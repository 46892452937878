import { getDoorWindowPosition } from "../helpers/buildingReducerHelper";

const params = {
    specular: "#494949",        //rgb(0.066, 0.066, 0.066)
    length: 20,
    width: 10,
    height: 7,
    minHeight: 5,
    maxHeight: 10,
    roofPitch: 6,
    minPitch: 3,
    maxPitch: 3,
    porchSize: 4,
    minPorchSize: 5,
    maxPorchSize: 10,
    buildingSizes: [], 
    sideExtension: 0.5,
    endExtension: 0.5,
    pillarThickness: 0.33,      // 4 inch = 8/24 (24 = 1ft = 12 inch) 
    baseHeight: 0.33,          // 4 inch
    baseLegHeight: 0.375,

    exteriorLegType: "type2",
    activeWall: "front",
    sidePanelTabExpanded: false,

    exteriorRoofTexture: "",
    exteriorSidingTexture: "",
    exteriorLegTexture: "",
    exteriorTrimTexture: "",
    exteriorFloorTexture: "",
    interiorRoofTexture: "",
    interiorSidingTexture: "",
    interiorFloorTexture: "",

    exteriorRoofImages: [],
    exteriorSidingImages: [],
    exteriorLegImages: [],
    exteriorTrimImages: [],
    exteriorFloorImages: [],
    interiorRoofImages: [],
    interiorSidingImages: [],
    interiorFloorImages: [],

    exteriorRoofColors: [],
    exteriorSidingColors: [],
    exteriorTrimColors: [],
    exteriorLegColors: [],
    exteriorFloorColors: [],
    interiorRoofColors: [],
    interiorSidingColors: [],
    interiorFloorColors: [],

    exteriorRoofColor: "",
    exteriorSidingColor: "",
    exteriorLegColor: "",
    exteriorTrimColor: "",
    exteriorFloorColor: "",
    interiorRoofColor: "",
    interiorSidingColor: "",
    interiorFloorColor: "",

    // innerWallColor: "#a5a394", 
    // outerWallColor: "#a5a394",
    // floorColor: "#a5a394",
    // roofColor: "#9a8974",
    // innerRoofColor: "#9a8974",
    
    doorWindowsData: [],
    doorWindowsUIData: [],
    openDoorWindowEditDialog: false,
    selectedDoorIndex: -1,
    doorWindowMovementData: {},

    isWallTransparent: false,
    wallTransparentOpacity: 1,
}

const constants = {
    buildingStyleImages: [],
    fonts: {}
}

const initialState = {
    params,
    constants,
    selectedTab: 'buildingType',
}


export default function buildingReducer(state = initialState, action) {

    switch(action.type) {
        case "SET_DOOR_WINDOW_MOVEMENT_DATA":
            state.params.doorWindowMovementData = action.value; 
            return {
                ...state,
                action
            }

        case "SET_DOOR_WINDOW_DATA":
            state.params.doorWindowsData = action.value; 
            return {
                ...state,
                action
            }
            
        case "OPEN_DOOR_WINDOW_EDIT":
            state.params.openDoorWindowEditDialog = true; 
            state.params.selectedDoorIndex = action.value; 
            return {
                ...state,
                action
            }

        case "CLONE_DOOR_WINDOW":
            const door = state.params.doorWindowsData.find((e, index) => index == action.value);
            var [isAvailable, posX, posY] = getDoorWindowPosition(state.params.doorWindowsData, state.params.doorWindowMovementData, state.params.activeWall, door);
            if (isAvailable) {
                state.params.doorWindowsData = [...state.params.doorWindowsData, Object.assign({}, {...door, posX, posY})];
            } else {
                alert("could not add");
            }
            state.params.openDoorWindowEditDialog = false; 
            state.params.selectedDoorIndex = -1; 
            return {
                ...state,
                action
            }

        case "REMOVE_DOOR_WINDOW":
            state.params.doorWindowsData = state.params.doorWindowsData.filter((e, index) => index != action.value)
            state.params.openDoorWindowEditDialog = false; 
            state.params.selectedDoorIndex = -1; 
            return {
                ...state,
                action
            }

        case "CLOSE_DOOR_WINDOW":
            state.params.openDoorWindowEditDialog = false; 
            state.params.selectedDoorIndex = -1; 
            return {
                ...state,
                action
            }
        
        case "UPDATE_DOOR_WINDOW":
            // const doorWindowsData = state.params.doorWindowsData.filter((item, index) => index != state.params.selectedDoorIndex);
            // const newItem = state.params.doorWindowsUIData.find((item, index) => item.value === action.value);
            // var [isAvailable, posX, posY] = getDoorWindowPosition(doorWindowsData, state.params.doorWindowMovementData, state.params.activeWall, newItem);

            const doorWindowsData = state.params.doorWindowsData.filter((item, index) => index !== state.params.selectedDoorIndex);
            const seleteddoor = state.params.doorWindowsData[state.params.selectedDoorIndex];
            if(seleteddoor) {
                const newItem = state.params.doorWindowsUIData.find((item) => item.value === action.value);
                const newDoor = {wall: seleteddoor.wall, posX: seleteddoor.posX, posY: seleteddoor.posY, ...newItem};
                var [isAvailable, posX, posY] = getDoorWindowPosition(doorWindowsData, state.params.doorWindowMovementData, state.params.activeWall, newDoor);
                
                if (isAvailable) {
                    state.params.doorWindowsData = [
                        ...state.params.doorWindowsData.with(state.params.selectedDoorIndex, {...newDoor, posX, posY})
                    ]
                } else {
                    alert("could not add");
                }
            }


            return {
                ...state,
                action
            }
        
        case "ADD_DOOR_WINDOW":
            const newDoor = {wall: state.params.activeWall, ...action.value};
            var [isAvailable, posX, posY] = getDoorWindowPosition(state.params.doorWindowsData, state.params.doorWindowMovementData, state.params.activeWall, newDoor);
            if (isAvailable) {
                state.params.doorWindowsData = [
                        ...state.params.doorWindowsData,
                    {
                        ...newDoor,
                        posX,
                        posY 
                    }
                ]
            } else {
                alert("could not add");
            }

        return {
            ...state,
            action
        }
        case "CHANGE_SIDE_PANEL_TAB_EXPANSION":
            state.params.sidePanelTabExpanded = action.value;

            if (state.params.sidePanelTabExpanded === "interiorMaterial") {
                state.params.isWallTransparent = true;
                state.params.wallTransparentOpacity = 0.1;
            } else {
                state.params.isWallTransparent = false;
                state.params.wallTransparentOpacity = 1;
            }

            return {
                ...state,
                action
            }

        case "SET_ACTIVE_WALL":
            state.params.activeWall = action.value;
            return {
                ...state,
                action
            }

        case "UPDATE_DOOR_WINDOW_POSITION":
            params.doorWindowsData = params.doorWindowsData.map((item, index) => {
                if(index === action.value.indexId) {
                    item.posX = action.value.posX;
                    item.posY = action.value.posY;
                }
                return item;
            })

            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_ROOF_TEXTURE":
            if (action.payload.name !== params.exteriorRoofTexture) params.exteriorRoofTexture = action.payload.name;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_SIDE_TEXTURE":
            if (action.payload.name !== params.exteriorSidingTexture) params.exteriorSidingTexture = action.payload.name;
            return {
                ...state,
                action
            }            

        case "SELECT_EXTERIOR_TRIM_TEXTURE":
            if (action.payload.name !== params.exteriorTrimTexture) params.exteriorTrimTexture = action.payload.name;
            return {
                ...state,
                action
            }
        case "SELECT_EXTERIOR_LEG_TEXTURE":
            if (action.payload.name !== params.exteriorLegTexture) params.exteriorLegTexture = action.payload.name;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_FLOOR_TEXTURE":
            if (action.payload.name !== params.exteriorFloorTexture) params.exteriorFloorTexture = action.payload.name;
            return {
                ...state,
                action
            }


        case "SELECT_INTERIOR_ROOF_TEXTURE":
            if (action.payload.name !== params.interiorRoofTexture) params.interiorRoofTexture = action.payload.name;
            return {
                ...state,
                action
            }

        case "SELECT_INTERIOR_SIDE_TEXTURE":
            if (action.payload.name !== params.interiorSidingTexture) params.interiorSidingTexture = action.payload.name;
            return {
                ...state,
                action
            }

        case "SELECT_INTERIOR_FLOOR_TEXTURE":
            if (action.payload.name !== params.interiorFloorTexture) params.interiorFloorTexture = action.payload.name;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_ROOF_COLOR":
            if (action.payload.code !== params.exteriorRoofColor) params.exteriorRoofColor = action.payload.code;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_SIDE_COLOR":
            if (action.payload.code !== params.exteriorSidingColor) params.exteriorSidingColor = action.payload.code;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_TRIM_COLOR":
            if (action.payload.code !== params.exteriorTrimColor) params.exteriorTrimColor = action.payload.code;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_LEG_COLOR":
            if (action.payload.code !== params.exteriorLegColor) params.exteriorLegColor = action.payload.code;
            return {
                ...state,
                action
            }

        case "SELECT_EXTERIOR_FLOOR_COLOR":
            if (action.payload.code !== params.exteriorFloorColor) params.exteriorFloorColor = action.payload.code;
            return {
                ...state,
                action
            }

        case "SET_BUILDING_DATA":
            state.constants.fonts = action.payload["fonts"];
            state.params.width  = action.payload["width"];
            state.params.length  = action.payload["length"];
            state.params.height  = action.payload["height"];
            state.params.minHeight  = action.payload["minHeight"];
            state.params.maxHeight  = action.payload["maxHeight"];
            state.params.roofPitch  = action.payload["roofPitch"];
            state.params.minPitch  = action.payload["minPitch"];
            state.params.maxPitch  = action.payload["maxPitch"];
            state.params.porchSize  = action.payload["porchSize"];
            state.params.minPorchSize  = action.payload["minPorchSize"];
            state.params.maxPorchSize  = action.payload["maxPorchSize"];
            state.params.buildingSizes  = action.payload["buildingSizes"];

            state.params.exteriorRoofImages = action.payload?.materials?.exterior.roof;
            state.params.exteriorSidingImages = action.payload?.materials?.exterior.siding;
            state.params.exteriorTrimImages = action.payload?.materials?.exterior.trim;
            state.params.exteriorLegImages = action.payload?.materials?.exterior.leg;
            state.params.exteriorFloorImages = action.payload?.materials?.exterior.floor;
            state.params.interiorRoofImages = action.payload?.materials?.interior.roof;
            state.params.interiorSidingImages = action.payload?.materials?.interior.siding;
            state.params.interiorFloorImages = action.payload?.materials?.interior.floor;
            
            state.params.exteriorRoofTexture = action.payload.exteriorRoofTexture;
            state.params.exteriorSidingTexture = action.payload.exteriorSidingTexture;
            state.params.exteriorLegTexture = action.payload.exteriorLegTexture;
            state.params.exteriorTrimTexture = action.payload.exteriorTrimTexture;
            state.params.exteriorFloorTexture = action.payload.exteriorFloorTexture;
            state.params.interiorRoofTexture = action.payload.interiorRoofTexture;
            state.params.interiorSidingTexture = action.payload.interiorSidingTexture;
            state.params.interiorFloorTexture = action.payload.interiorFloorTexture;

            state.params.exteriorRoofColors = action.payload?.colors?.exterior.roof;
            state.params.exteriorSidingColors = action.payload?.colors?.exterior.siding;
            state.params.exteriorTrimColors = action.payload?.colors?.exterior.trim;
            state.params.exteriorLegColors = action.payload?.colors?.exterior.leg;
            state.params.exteriorFloorColors = action.payload?.colors?.exterior.floor;
            state.params.interiorRoofColors = action.payload?.colors?.interior.roof;
            state.params.interiorSidingColors = action.payload?.colors?.interior.siding;
            state.params.interiorFloorColors = action.payload?.colors?.interior.floor;
            
            state.params.exteriorRoofColor = action.payload.exteriorRoofColor;
            state.params.exteriorSidingColor = action.payload.exteriorSidingColor;
            state.params.exteriorLegColor = action.payload.exteriorLegColor;
            state.params.exteriorTrimColor = action.payload.exteriorTrimColor;
            state.params.exteriorFloorColor = action.payload.exteriorFloorColor;
            state.params.interiorRoofColor = action.payload.interiorRoofColor;
            state.params.interiorSidingColor = action.payload.interiorSidingColor;
            state.params.interiorFloorColor = action.payload.interiorFloorColor;

            state.params.doorWindowsUIData = action.payload.doorWindowsUIData;
            state.params.doorWindowsData = action.payload.doorWindowsData;

            state.constants.buildingStyleImages = action.payload.buildingStyleImages;

        return {
            ...state,
            action
        }
        case "CHANGE_BUILDING_DIMENSION":
                let size = action.payload.split('x');
                if (size) {
                    state.params.width = Number(size[0]);
                    state.params.length = Number(size[1]);
                } 
            return {
                ...state,
                action
            }

        case "CHANGE_BUILDING_HEIGHT":
            state.params.height = action.payload;

            return {
                ...state,
                action
            }

        case "CHANGE_BUILDING_PITCH":
            state.params.roofPitch = action.payload;

            return {
                ...state,
                action
            }   
            
        case "CHANGE_PORCH_SIZE":
            state.params.porchSize = action.payload;

            return {
                ...state,
                action
            }              
        default:
            // console.log("such action type not available", action);
            return {
                ...state
            }
    }
}