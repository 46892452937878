import { useSelector } from "react-redux";
import { useMemo } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../../../utils/Config";
import { IntSidingTexture, extrudeSettings } from "../../../../../../../utils/Functions";

export default function BackInnerWall() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const color = useSelector((state) => state.buildingReducer.params.color);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);

    const scaledHalfHeight =  (height / 2) * configData.hScale;;

    const backInnerWall = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale, -scaledHalfHeight);
        shape.lineTo(-(width / 2) * configData.wScale, scaledHalfHeight);
        shape.lineTo(0, (height / 2 + ((roofPitch / 12) * (width / 2))) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, scaledHalfHeight);
        shape.lineTo((width / 2) * configData.wScale, -scaledHalfHeight);
        shape.closePath();

        const doors = doorWindowsData.filter((door) => door.wall === "back");
        doors.forEach((door) => {
            const path = new THREE.Path();
            path.moveTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.lineTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.closePath();
            shape.holes.push(path);
        })

        return shape;
    }, [length, width, height, roofPitch, doorWindowsData]);

    const texture = IntSidingTexture();

    return (
        <mesh name="back-inner-wall" castShadow receiveShadow position={[0, height / 2 * configData.hScale, -(length / 2) * configData.wScale + 1]}>
            <shapeGeometry args={[backInnerWall]}/>
            <meshPhongMaterial 
                map={texture} 
                color={color} 
            />
        </mesh>
    )
}