import { useHelper } from "@react-three/drei"
import { useRef } from "react"
import { useSelector } from "react-redux";
import { DirectionalLightHelper, PointLightHelper } from "three";
import { configData } from "../../../utils/Config";

export default function Light(props) {

    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const isLight = useSelector((state) => state.envReducer.isLight);
    
    const pointRef = useRef();
    const pointRef1 = useRef();
    const dirRef = useRef();
    // const dir1Ref = useRef();
    // const dir2Ref = useRef();
    // const dir3Ref = useRef();
    // const dir4Ref = useRef();

    // useHelper(pointRef, PointLightHelper, 1, 'red');
    // useHelper(pointRef1, PointLightHelper, 1, 'green');


    // useHelper(dirRef, DirectionalLightHelper, 1, 'red');
    // useHelper(dir1Ref, DirectionalLightHelper, 1, 'red');
    // useHelper(dir2Ref, DirectionalLightHelper, 1, 'red');
    // useHelper(dir3Ref, DirectionalLightHelper, 1, 'red');
    // useHelper(dir4Ref, DirectionalLightHelper, 1, 'red');

    const setLights = (name) => {
        switch(name) {
            case "lights1":
                return (
                    <group name={`${name}-group`}>
                        <ambientLight color={"#E4E4E4"} intensity={1.7} />
                        
                        <directionalLight ref={dirRef} intensity={3} color={"#f7f7df"}  position={[-width/4 * configData.wScale, 2 * height * configData.hScale, length/4 * configData.wScale]} 
                            castShadow 
                            shadow-mapSize={4096}
                            shadow-camera-near={0.1} 
                            shadow-camera-far={5000} 
                            shadow-camera-left={-1000} 
                            shadow-camera-top={1000} 
                            shadow-camera-right={1000} 
                            shadow-camera-bottom={-1000} 
        
                        />
                        <directionalLight intensity={2} position={[-300, 300, 0]} />
                        <directionalLight intensity={2} position={[0, 300, 300]} />
                        <directionalLight intensity={2} position={[0, 300, -300]}/>
                        <directionalLight intensity={2} position={[300, 300, 0]}/>
                    </group>  
                )
            
            case "lights2": 
                return (
                    <group name={`${name}-group`}>
                        <directionalLight ref={dirRef} intensity={1.5} color={"#fffee2"} position={[-width/4 * configData.wScale, 2 * height * configData.hScale, length/4 * configData.wScale]}
                            castShadow
                            shadow-mapSize={2048}
                            shadow-camera-near={1}
                            shadow-camera-far={1000}
                            shadow-camera-left={-500}
                            shadow-camera-top={500}
                            shadow-camera-right={500}
                            shadow-camera-bottom={-500}
                        />
                        <ambientLight intensity={1.8} />
                        <pointLight ref={pointRef} decay={0} intensity={1.5} position={[- (width/2 + 9 ) * configData.wScale, height/2 * configData.hScale,  (length/2 + 9) * configData.wScale]}/>
                        <pointLight ref={pointRef1} color={"#fef1e0"} decay={0} intensity={1.5} position={[(width/2 + 9) * configData.wScale, height/2 * configData.hScale, -(length/2 + 9) * configData.wScale]} /> 
                    </group>
                )
        }
     
    }


    return (
        isLight && setLights(props.name)
    )
}