import { useSelector } from "react-redux";
import Pillar from "./Pillar";
import PorchWall from "./PorchWall";
// import { useEffect } from "react";
// import { useThree } from "@react-three/fiber";
// import * as THREE from 'three'
import { configData } from "../../../../utils/Config";

export default function Porch() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const pillarThickness = useSelector((state) => state.buildingReducer.params.pillarThickness) * configData.wScale;    
    const pillarOffsetGap = (0.083/2) * configData.wScale + pillarThickness/2;              //1inch = 0.083 if wscale = 24
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize) * configData.wScale;   

    return (
        <group name="porch" position={[0, 0, (length / 2) * configData.wScale]}>
            <Pillar name="pillar-front" height={height * configData.hScale} position={[ 0, 0, -pillarOffsetGap]}/>
            <Pillar name="pillar-front-left-corner" 
                placement="corner"  
                height={height * configData.hScale} 
                position={[(-width /2) * configData.wScale + pillarOffsetGap, 0, -pillarOffsetGap]} 
            />
            <Pillar 
                name="pillar-front-right-corner" 
                placement="corner" 
                height={height * configData.hScale} 
                position={[(width /2) * configData.wScale - pillarOffsetGap, 0, -pillarOffsetGap]}
                rotation={[0, Math.PI / 2, 0]}
            />
            <PorchWall 
                name="porch-wall-front" 
                position={[(-width/2 ) * configData.wScale + pillarOffsetGap + pillarThickness/2, 2, -pillarOffsetGap]} 
                width={(width/2) * configData.wScale - pillarOffsetGap - pillarThickness} 
                height={height / 2.5 * configData.hScale} 
            />
            <PorchWall 
                name="porch-wall-left" 
                position={[(-width/2 ) * configData.wScale + pillarOffsetGap, 2, - porchSize + 3]} 
                rotation={[0, -Math.PI/2, 0]}
                width={porchSize -pillarOffsetGap - (pillarThickness/2) - (0.083) * configData.wScale -1} 
                height={height / 2.5 * configData.hScale} 
            />
              <PorchWall 
                name="porch-wall-right" 
                position={[(width/2 ) * configData.wScale - pillarOffsetGap, 2, -pillarOffsetGap - (pillarThickness/2)]} 
                rotation={[0, Math.PI/2, 0]}
                width={porchSize -pillarOffsetGap - (pillarThickness/2) - (0.083) * configData.wScale -1 } 
                height={height / 2.5 * configData.hScale} 
            />
        </group>
    )

}