import * as THREE from 'three'
import { configData } from '../Config';

export const Base = (width, length, porchSize) => {
    const offset = 4;
    const shape = new THREE.Shape();
    shape.moveTo((width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
    shape.lineTo((width / 2) * configData.wScale, -(length / 2) * configData.wScale);
    shape.lineTo(-(width / 2) * configData.wScale, -(length / 2) * configData.wScale);
    shape.lineTo(-(width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
    shape.closePath();

    const path = new THREE.Path();
    path.moveTo((width / 2) * configData.wScale - offset, (length / 2 - porchSize) * configData.wScale - offset);
    path.lineTo(-(width / 2) * configData.wScale + offset, (length / 2 - porchSize) * configData.wScale - offset);
    path.lineTo(-(width / 2) * configData.wScale + offset, -(length / 2) * configData.wScale + offset);
    path.lineTo((width / 2) * configData.wScale - offset, -(length / 2) * configData.wScale + offset);
    path.closePath();

    shape.holes.push(path);

    const shape1 = new THREE.Shape();
    shape1.moveTo((width / 2) * configData.wScale, (length / 2) * configData.wScale);
    shape1.lineTo((width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
    shape1.lineTo(-(width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
    shape1.lineTo(-(width / 2) * configData.wScale, (length / 2) * configData.wScale);
    shape1.closePath();

    const path1 = new THREE.Path();
    path1.moveTo((width / 2) * configData.wScale - offset, (length / 2) * configData.wScale - offset);
    path1.lineTo(-(width / 2) * configData.wScale + offset, (length / 2) * configData.wScale - offset);
    path1.lineTo(-(width / 2) * configData.wScale + offset, (length / 2 - porchSize) * configData.wScale + offset);
    path1.lineTo((width / 2) * configData.wScale - offset, (length / 2 - porchSize) * configData.wScale + offset);
    path1.closePath();

    shape1.holes.push(path1);

    return [shape, shape1];
}