import InnerWall from "./RightInnerWall"
import OuterWall from "./RightOuterWall"

export default function RightWall() {
    return (
        <group name="rightwall">
            <InnerWall />
            <OuterWall />
        </group>
    )
}