import FrontInnerWall from "./FrontInnerWall"
import FrontOuterWall from "./FrontOuterWall"
import FrontTrim from "./FrontWallTrim"

export default function FrontWall() {

    return (
        <group>
            <FrontInnerWall />
            <FrontOuterWall />
            <FrontTrim />
        </group>
    )
}