import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";


export default function SummaryComponent({expanded, onChange}) {

    return (
        <>
            <Accordion expanded={expanded === "summary"} onChange={(event, isExpanded) => onChange(isExpanded, "summary")} elevation={0} disableGutters>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>
                    <Typography>SUMMARY</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>Details</Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}