

const initialState = {
    isGround: false,
    isShadowGround: true,
    isFog: false,
    isBackground: true,
    isLight: true,
    backgroundName: "standard",
    backgroundImg: "background1",
    orbitControlsRef: null
}

export default function envReducer(state = initialState, action) {

    switch(action.type) {
        case "addOrbitControlsRef":
            state.orbitControlsRef = action.payload
            return {
                ...state,
                action
            }
        case "toggleGround":
            return {
                ...state,
                action
            }
        
        default:
            // console.log("such action type not available", action);
            return {
                ...state
            }
    }
}


