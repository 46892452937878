import { useMemo } from "react"
import { extrudeSettings } from "../../../../../utils/Functions"
import * as THREE from 'three'

export default function HoleModel({width, height, thickness, position}) {
    const shape1Thickness = 0.75;
    const shape1Bevel = 0.75;

    const shape2Thickness = 0.75;
    const shape2Bevel = 0.75;

    const shape1 = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-width/2, -height/2);
        shape.lineTo(width/2, -height/2);
        shape.lineTo(width/2, height/2);
        shape.lineTo(-width/2, height/2);
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(-width/2 + shape1Thickness, -height/2 + shape1Thickness);
        path.lineTo(width/2 - shape1Thickness, -height/2 + shape1Thickness);
        path.lineTo(width/2 - shape1Thickness, height/2 - shape1Thickness);
        path.lineTo(-width/2 + shape1Thickness, height/2 - shape1Thickness);
        path.closePath();

        shape.holes.push(path);

        return shape;
    }, [width, height]);

    const shape2 = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-width/2, -height/2);
        shape.lineTo(width/2, -height/2);
        shape.lineTo(width/2, height/2);
        shape.lineTo(-width/2, height/2);
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(-width/2 + shape2Thickness, -height/2 + shape2Thickness);
        path.lineTo(width/2 - shape2Thickness, -height/2 + shape2Thickness);
        path.lineTo(width/2 - shape2Thickness, height/2 - shape2Thickness);
        path.lineTo(-width/2 + shape2Thickness, height/2 - shape2Thickness);
        path.closePath();

        shape.holes.push(path);

        return shape;
    }, [width, height]);

    const shape3 = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-width / 2, -height / 2);
        shape.lineTo( width / 2, -height / 2);
        shape.lineTo( width / 2,  height / 2);
        shape.lineTo(-width / 2,  height / 2);
        shape.closePath();
        return shape;
    }, [width, height]);
    
    const shape4 = useMemo(() => {
        const offset = 6;
        const shape = new THREE.Shape();
        shape.moveTo(-(width - offset) / 2, -(height - offset) / 2);
        shape.lineTo( (width - offset) / 2, -(height - offset) / 2);
        shape.lineTo( (width - offset) / 2,  (height - offset) / 2);
        shape.lineTo(-(width - offset) / 2,  (height - offset) / 2);
        shape.closePath();
        return shape;
    }, [width, height]);

    const shape5 = useMemo(() => {
        const offset = 6.5;
        const shape = new THREE.Shape();
        shape.moveTo(-(width - offset) / 2, -(height - offset) / 2);
        shape.lineTo( (width - offset) / 2, -(height - offset) / 2);
        shape.lineTo( (width - offset) / 2,  (height - offset) / 2);
        shape.lineTo(-(width - offset) / 2,  (height - offset) / 2);
        shape.closePath();
        return shape;
    }, [width, height]);

    return (
        <group position={position}>
            <mesh position={[0, 0, shape1Bevel]}>
                <extrudeGeometry args={[shape1, extrudeSettings(thickness - 2 * shape1Bevel, shape1Bevel, shape1Bevel, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>

            {/* <mesh position={[0, 0, shape2Bevel]}>
                <extrudeGeometry args={[shape2, extrudeSettings(thickness - 2 * shape2Bevel, shape2Bevel, shape2Bevel, 0, 1)]} />
                <meshStandardMaterial color={'green'} />
            </mesh> */}

            <mesh position={[0, 0, 1.5 * shape1Bevel]}>
                <extrudeGeometry args={[shape3, extrudeSettings(thickness - 3 * shape1Bevel)]} />
                <meshStandardMaterial />
            </mesh>

            <mesh position={[0, 0, 0.75]}>
                <extrudeGeometry args={[shape4, extrudeSettings(thickness - 1.5, 0.75, 0.75, 0, 1)]} />
                <meshStandardMaterial />
            </mesh>

            
            <mesh position={[0, 0, -0.2]}>
                <extrudeGeometry args={[shape5, extrudeSettings(thickness + 0.4, 0, 0, 0, 1)]} />
                <meshStandardMaterial />
            </mesh>
        </group>

        
    )
}