
import { useMemo } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Images from '../../../assets/image'
import * as THREE from "three";

export default function Background(props) {


    const isBackground = useSelector((state) => state.envReducer.isBackground);
    const backgroundImg = useSelector((state) => state.envReducer.backgroundImg);
    const backgroundName = useSelector((state) => state.envReducer.backgroundName);

    const texture = useMemo(() => {
        const texture = new THREE.TextureLoader().load(Images[backgroundImg]);

        return texture;
    }, [backgroundImg])

    const getBackground = () => {
        switch(backgroundName) {
            case "standard":
                let fragmentShader = 'uniform vec3 topColor;    uniform vec3 bottomColor;    uniform float offset;    uniform float exponent;    varying vec3 vWorldPosition;    void main() {      float h = normalize( vWorldPosition + offset ).y;      gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );    }'

                let vertexShader = 'varying vec3 vWorldPosition;    void main() {      vec4 worldPosition = modelMatrix * vec4( position, 1.0 );      vWorldPosition = worldPosition.xyz;      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );    }'
            
                let uniforms = {
                    exponent: {
                        value: 0.5
                    },
                    offset: {
                        value: 0
                    },
                    topColor: {
                        value: new THREE.Color("#ffffff")
                    },
                    bottomColor: {
                        value: new THREE.Color().setRGB(0.8666666666666667, 0.8666666666666667, 0.8666666666666667)
                    }
                }
            
                // let material = new THREE.ShaderMaterial({uniforms, fragmentShader, vertexShader});
                // let geometry = new THREE.SphereGeometry(200, 4, 16);

                return (
                    <mesh>
                        <sphereGeometry args={[props.rad, 16, 4]} />
                        <shaderMaterial side={THREE.DoubleSide} uniforms={uniforms} fragmentShader={fragmentShader} vertexShader={vertexShader} />
                    </mesh>
                )

            case "scene2":
                return (
                    <mesh>
                        <sphereGeometry args={[props.rad, 32, 32]} />
                        <meshBasicMaterial attach="material" side={THREE.DoubleSide} map={texture}/>
                    </mesh>
                )
            
        }
    }


    return (
        isBackground &&
        <group name="world-sphere-group">
            {
                getBackground()
            }
        </group>
    )
}