import { useEffect, useMemo, useRef, useState } from "react"
import ElevenLiteDoor from "./ElevenLiteDoor"
import { useDispatch, useSelector } from "react-redux";
import { useThree, extend, useLoader } from "@react-three/fiber";
import * as THREE from 'three'
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { configData } from "../../../utils/Config";
import NineLiteDoor from "./NineLiteDoor";
import NineLiteWindow from "./NineLiteWindow";
import FourLiteColonialWindow from "./FourLiteColonialWindow";
import ColonistDoor from "./ColonistDoor";
import NineLiteDoubleDoor from "./NineLiteDoubleDoor";
import ElevenLiteDoubleDoor from "./ElevenLiteDoubleDoor";
import ColonistDoubleDoor from "./ColonistDoubleDoor";
import { Html } from "@react-three/drei";
import { ModeEdit } from '@mui/icons-material'
import Fab from '@mui/material/Fab'
import { convertToFeetInches } from "../../../utils/Functions";
import DimensionLabel from "./Common/DimensionLabel";


export default function DoorWindows({indexId, id, type, name, value, width, height, trimWidth, thickness, wall, leftLimit, rightLimit, topLimit, bottomLimit, coordinate, defaultPosition, posX, posY, setOverlappedComponents, overlapped, ...props}) {
    const orbitControlsRef = useSelector((state) => state.envReducer.orbitControlsRef);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);
    const activeWall = useSelector((state) => state.buildingReducer.params.activeWall);
    const sidePanelTabExpanded = useSelector((state) => state.buildingReducer.params.sidePanelTabExpanded);
    const get = useThree((state) => state.get);
    const dispatch = useDispatch();
    const [distances, setDistances] = useState({x: '', y: '', z: ''});
    const ref = useRef(null);
    const [isMoving, setIsMoving] = useState(false);
    const [prevPos, setPrevPos] = useState(new THREE.Vector3());
    const [hover, setHover] = useState(false);
    const [focus, setFocus] = useState(false);
    
    
    const position = useMemo(() => {
        return new THREE.Vector3(coordinate == "x" ? defaultPosition.x + posX: defaultPosition.x, defaultPosition.y + posY, coordinate == "z" ? defaultPosition.z + posX: defaultPosition.z);
    }, [defaultPosition, posX, posY]);


    const door = useMemo(() => {
         return doorWindowFactory(value, width, height, trimWidth, thickness);
    }, [value]);


    const events = useMemo(() => {
        return (function() {
            let dragging = false; 
            let plane = new THREE.Plane();
            let offset = new THREE.Vector3();
            let isOverlapped = false;

            let intersection = new THREE.Vector3();
            let inverseMatrix = new THREE.Matrix4();
            let planeUp = new THREE.Vector3(1, 0, 1);
            planeUp[coordinate] = 0;

            return {
                onDocumentPointerMove: function(e, currentObject) {       
                    e.stopPropagation();
                    e.preventDefault();

                    if (dragging && get().raycaster.ray.intersectPlane(plane, intersection)) {    
                        const intersectPos = intersection.sub(offset).applyMatrix4(inverseMatrix);
            
                        if (type === "door" ){
                            currentObject.position[coordinate] = Math.min(rightLimit - (width/2 * configData.wScale + trimWidth), Math.max(leftLimit + (width/2 * configData.wScale + trimWidth), intersectPos[coordinate]))
                        } else {
                            currentObject.position[coordinate] = Math.min(rightLimit - (width/2 * configData.wScale + trimWidth), Math.max(leftLimit + (width/2 * configData.wScale + trimWidth), intersectPos[coordinate]))
                            currentObject.position.y = Math.min(topLimit - (height/2 * configData.hScale + trimWidth), Math.max(bottomLimit +(height/2 * configData.hScale + trimWidth), intersectPos.y))
                        }        
                        
                        
                        let l_distance = wall === "front" || wall === "left" ? 
                        (leftLimit - currentObject.position[coordinate] + (width / 2 * configData.wScale + trimWidth)): 
                        (rightLimit - currentObject.position[coordinate] - (width / 2 * configData.wScale + trimWidth));
                        
                        let r_distance = wall === "front" || wall === "left" ? 
                        (rightLimit - currentObject.position[coordinate] - (width / 2 * configData.wScale + trimWidth)): 
                        (leftLimit - currentObject.position[coordinate] + (width / 2 * configData.wScale + trimWidth));

                        let b_distance = currentObject.position.y - bottomLimit - (height/2 * configData.hScale);

                        setDistances({x: convertToFeetInches(l_distance/configData.wScale), y: convertToFeetInches(b_distance/configData.hScale), z: convertToFeetInches(r_distance/configData.wScale)});
                        let overlappedDoors = new Set();
                        doorWindowsData.map((door, index) => {
                            if (door.wall == wall){
                                if (index === indexId) {
                                    overlappedDoors.add(door);
                                } else {
                                    if ((door.posX + (door.width/2 * configData.wScale + trimWidth) > (currentObject.position[coordinate] - (width/2 * configData.wScale + trimWidth)) &&
                                        door.posX - (door.width/2 * configData.wScale + trimWidth) < (currentObject.position[coordinate] + (width/2 * configData.wScale + trimWidth))) &&
                                        ((door.posY + (door.height/2 * configData.hScale + trimWidth) + bottomLimit)> (currentObject.position.y - (height/2 * configData.hScale + trimWidth)) &&
                                        (door.posY - (door.height/2 * configData.hScale + trimWidth) + bottomLimit) < (currentObject.position.y + (height/2 * configData.hScale + trimWidth)))
                                    ) {
                                        overlappedDoors.add(door);
                                    }
                                }
                            }
                        });

                        if(overlappedDoors.size > 1){
                            isOverlapped = true;
                            setOverlappedComponents(overlappedDoors);
                        } else if(overlappedDoors.size === 1){
                            isOverlapped = false;
                            setOverlappedComponents(new Set());
                        }

                    }
                },

                onPointerDown: function(e) {

                    e.stopPropagation();

                    e.defaultPrevented = true;
                    
                    const lPosition = new THREE.Vector3();
                    
                    lPosition.setFromMatrixPosition(e.eventObject.matrix);
            
                    plane.setFromNormalAndCoplanarPoint(planeUp, lPosition);
            
                    e.ray.intersectPlane(plane, intersection);
            
                    offset.copy(intersection.sub(lPosition));
            
                    dragging = true;
            
                    prevPos.copy(e.eventObject.position);
                    
                    setPrevPos(prevPos);
                    
                    inverseMatrix.copy(e.eventObject.parent.matrixWorld).invert();
                    
                    orbitControlsRef.current.enabled = false;
                     
                    get().gl.domElement.style.cursor = "move";

                    setIsMoving(true);
                    setFocus(true);
                    
                },

                onDocumentPointerUp: function(e, currentObject) {

                    e.stopPropagation();
                    e.preventDefault();

                    setIsMoving(false);
            
                    if (dragging) {
            
                        orbitControlsRef.current.enabled = true;
            
                        get().gl.domElement.style.cursor = "auto";
            
                        dragging = false
            
                        if (isOverlapped) {     
                            isOverlapped = false;
                            setOverlappedComponents(new Set());                            
                            currentObject.position.copy(prevPos);
                        } else {
                            dispatch({
                                type: "UPDATE_DOOR_WINDOW_POSITION",
                                value: {
                                    indexId,
                                    posX: currentObject.position[coordinate],
                                    posY: currentObject.position.y - bottomLimit
                                }
                            })
                        }
                    }
                }
            }
        })()
    }, [doorWindowsData]);

    const events2 = {
        onClick: function() {
            if(ref.current?.position.equals(prevPos)) {
                dispatch({
                    type: "OPEN_DOOR_WINDOW_EDIT",
                    value: indexId
                })
            }
        },
        onPointerOver: function(e) {
            e.stopPropagation();
            e.face?.normal?.z >= 0 && setHover(true);
        },
        onPointerLeave: function(e) {
            e.stopPropagation();
            setHover(false);
        },
        onPointerUp: function(e) {
            e.stopPropagation();
            console.log(e);
        }
    }

    const onDocumentUp = useEffect(() => {
        const func = (e) => {
            // if(!isMoving) {
                console.log("on document");
            // }

            setFocus(false);
        }

        !isMoving && document.addEventListener("mouseup", func);

        return () => {
            document.removeEventListener("mouseup", func);
        }
    }, [isMoving]);

    const outline = useMemo(() => {
        const shape = new THREE.Shape()
        shape.moveTo( -width/2 * configData.wScale - trimWidth, -height/2 * configData.hScale - trimWidth);
        shape.lineTo( -width/2 * configData.wScale - trimWidth, height/2 * configData.hScale + trimWidth);
        shape.lineTo( width/2 * configData.wScale + trimWidth, height/2 * configData.hScale + trimWidth);
        shape.lineTo( width/2 * configData.wScale + trimWidth, -height/2 * configData.hScale - trimWidth);
        shape.closePath();
        return new LineGeometry().setPositions(shape.getPoints().map((point) => [...point, 0]).flat(1));   
    }, [width, height, trimWidth])


    useEffect(() => {
        const callback = (e) => events.onDocumentPointerUp(e, ref.current);
        document.addEventListener("mouseup", callback);

        const callback1 = (e) => events.onDocumentPointerMove(e, ref.current);
        document.addEventListener("mousemove", callback1)

        return () => {
            document.removeEventListener("mouseup", callback);
            document.removeEventListener("mousemove", callback1);
        }
    }, [doorWindowsData])


    return (
        <group ref={ref} name={name} 
           {...events2}
            onPointerDown={events.onPointerDown} 
            position={position} {...props}
        >
            {
                focus 
                && (<>
                    <line2 geometry={outline} position={[0, 0, 3]}>
                        <lineMaterial args={[{color: 'orange', transparent: true, depthTest: false, linewidth: 5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                    <line2 geometry={outline} position={[0, 0, 3]}>
                        <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 2.5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                </>)
            }
            {
                hover && <line2 geometry={outline} position={[0, 0, 3]}>
                    <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 1, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                </line2>
            } 
            
            {
                door
            }
            
            {
                isMoving && <>
                    <DimensionLabel text={distances.x} position={[-width/2 * configData.wScale - 20, height/2 * configData.hScale + 11, 5]}/>
                    <DimensionLabel text={distances.z} position={[width/2 * configData.wScale + 20, height/2 * configData.hScale + 11, 5]}/>            
                    { type !== "door" && <DimensionLabel text={distances.y} position={[0, -height/2 * configData.hScale -11, 5]}/> }
                </>
                
            }

            <group name="overlapping-group" position={[0, 0, (thickness) / 2]}>
                {   
                    overlapped && <mesh>
                        <boxGeometry args={[width * configData.wScale + (2 * trimWidth), height * configData.hScale + (2 * trimWidth), thickness + 1]}/>
                        <meshBasicMaterial color={'red'} transparent opacity={0.55}/>
                    </mesh>
                }
            </group>

            {
                sidePanelTabExpanded === "doorWindows" && activeWall === wall && <group name="door-window-icon-group" position={[width/2 * configData.wScale + trimWidth, height/2 * configData.hScale + trimWidth, 0]}>
                    <Html style={{left: '5px', top: '-5px', zIndex: -116661424}}>
                        <Fab onClick={(e) => events2.onClick(e, indexId)} size="small">
                            <ModeEdit></ModeEdit>
                        </Fab>
                    </Html> 
                </group>
            }
        </group>
    )
}

function doorWindowFactory(value, width, height, trimWidth, thickness) {
    switch(value) {
        case "ninelitewindow":
            return <NineLiteWindow 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;
        case "fourlitecolonialwindow":
            return <FourLiteColonialWindow 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;       
        case "elevenlitedoor": 
            return <ElevenLiteDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth} 
                    thickness={thickness}
                />;
        case "ninelitedoor":
            return <NineLiteDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;                    
        case "colonistdoor":
            return <ColonistDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;    
        case "ninelitedoubledoor":
            return <NineLiteDoubleDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;      
        case "elevenlitedoubledoor":
            return <ElevenLiteDoubleDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;   
        case "colonistdoubledoor":
            return <ColonistDoubleDoor 
                    width={width * configData.wScale} 
                    height={height * configData.hScale} 
                    trimWidth={trimWidth}
                    thickness={thickness}
                />;                                               

        }
}