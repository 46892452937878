import { Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Stack, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import './temp.css'


export default function ExteriorMaterialComponent({expanded, onChange}) {
    const exteriorRoofImages = useSelector((state) => state.buildingReducer.params.exteriorRoofImages);
    const exteriorSidingImages = useSelector((state) => state.buildingReducer.params.exteriorSidingImages);
    const exteriorLegImages = useSelector((state) => state.buildingReducer.params.exteriorLegImages);
    const exteriorTrimImages = useSelector((state) => state.buildingReducer.params.exteriorTrimImages);
    const exteriorFloorImages = useSelector((state) => state.buildingReducer.params.exteriorFloorImages);
    const dispatch = useDispatch();

    const handleExteriorTextureChange = (type, payload) => {
        dispatch({
            type,
            payload
        })
    }

    return (
        <>
            <Accordion expanded={expanded === "exteriorMaterial"}  onChange={(event, isExpanded) => onChange(isExpanded, "exteriorMaterial")} elevation={0} disableGutters classes={{'css-9sgtv1-MuiPaper-root-MuiAccordion-root:before': {display: 'none'}}}>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "3px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>   
                    <Typography>EXTERIOR MATERIAL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Typography>Exterior Roof Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorRoofImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleExteriorTextureChange("SELECT_EXTERIOR_ROOF_TEXTURE", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider />
                        
                        <Stack spacing={1}>
                            <Typography>Exterior Siding Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorSidingImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleExteriorTextureChange("SELECT_EXTERIOR_SIDE_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider />
                        
                        <Stack spacing={1}>
                            <Typography>Exterior Trim Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorTrimImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleExteriorTextureChange("SELECT_EXTERIOR_TRIM_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider />

                        <Stack spacing={1}>
                            <Typography>Exterior Leg Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorLegImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleExteriorTextureChange("SELECT_EXTERIOR_LEG_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider />

                        <Stack spacing={1}>
                            <Typography>Exterior Floor Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorFloorImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleExteriorTextureChange("SELECT_EXTERIOR_FLOOR_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    )
}