import { configData } from "../Config"
import * as THREE from 'three'


export function Leg(height, length) {
    const legHeight = height * 0.75;
    const legHeight1 = height * 0.25;
    const shape = new THREE.Shape();
    shape.lineTo(0, (length / 2) * configData.wScale);
    shape.moveTo(legHeight * configData.hScale, (length / 2) * configData.wScale);
    shape.lineTo(height * configData.hScale, (length / 2 - legHeight1) * configData.wScale);
    shape.lineTo(height * configData.hScale, -(length / 2 - legHeight1) * configData.wScale);
    shape.lineTo(legHeight * configData.hScale, -(length / 2) * configData.wScale);
    shape.lineTo(0, -(length / 2) * configData.wScale);
    shape.closePath();

    return shape;
}
