import InnerWall from "./LeftInnerWall"
import OuterWall from "./LeftOuterWall"

export default function LeftWall() {
    return (
        <group name="leftwall">
            <InnerWall />
            <OuterWall />
        </group>
    )
}