import { useMemo } from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { Base } from "../../../../utils/Floor/Base"
import { Leg } from "../../../../utils/Floor/Leg"
import { Interior } from "../../../../utils/Floor/Interior"
import { PorchBase } from "../../../../utils/Floor/PorchBase"
import { extrudeSettings, IntFloorTexture, ExtFloorTexture, ExtTrimTexture } from "../../../../utils/Functions"
import * as THREE from "three"
import { configData } from "../../../../utils/Config"

export default function Floor(props) {

    const width = useSelector((state) => state.buildingReducer.params.width);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const baseLegHeight = useSelector((state) => state.buildingReducer.params.baseLegHeight);
    const floorColor = useSelector((state) => state.buildingReducer.params.exteriorFloorColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular)
    const noOfLegs = 5;
    const legSpace = width/noOfLegs;
    const legStartPos = -width/2 + legSpace/2;

    const base = useMemo(() => {
        return Base(width, length, porchSize);
    }, [width, length]);


    const leg = useMemo(() => {
        return Leg(baseLegHeight, length);
    }, [length]);

    const interior = useMemo(() => {
        return Interior("cabin", width, length, porchSize);
    }, [width, length, porchSize])

    const porchBase = useMemo(() => {
        return PorchBase("cabin", width, length, porchSize);
    }, [width, length, porchSize])

    const innerWallTexture = IntFloorTexture();
    const porchTexture = ExtFloorTexture();
    const [floorTexture, isNormalMap] = ExtTrimTexture();
    const legMatProps = {  
        map:isNormalMap? null: floorTexture,
        bumpMap:isNormalMap? null: floorTexture,
        normalMap:isNormalMap? floorTexture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:floorColor,        
        specular
    }
    isNormalMap && (legMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);
    
    return (
        <group name="floor" {...props}>
            <mesh name="base" position={[0, 0, 0]}>
                <extrudeGeometry args={[base, extrudeSettings(baseHeight * configData.hScale)]}/>
                <meshPhongMaterial {...legMatProps} />
            </mesh>

            {
                Array(noOfLegs).fill(0).map((item, index) => {
                    return <mesh key={index} name="leg" position={[(legStartPos + (index * legSpace) ) * configData.wScale, 0, baseHeight * configData.hScale]} rotation={[0, -Math.PI/2, 0]}>
                        <extrudeGeometry args={[leg, extrudeSettings(baseLegHeight * configData.hScale)]}/>
                        <meshPhongMaterial {...legMatProps} />
                    </mesh>
                })
            }
            
            <mesh name="porchBase"  castShadow receiveShadow position={[0, 0, -2]}>
                <extrudeGeometry args={[porchBase, extrudeSettings(2)]} />
                <meshPhongMaterial                     
                    map={porchTexture}                     
                />
            </mesh>

            <mesh name="interior"  castShadow receiveShadow position={[0, 0, -2]}>
                <extrudeGeometry args={[interior, extrudeSettings(2)]} />
                <meshPhongMaterial                     
                    map={innerWallTexture}
                />
            </mesh>

        </group>
    )
}