import { useMemo } from "react"
import * as THREE from 'three'
import { extrudeSettings } from "../../../../../utils/Functions";
import { configData } from "../../../../../utils/Config";


export default function Window({width, height, thickness}) {
    const unitY = height/3;
    const unitX = width/3;
    const trimWidth = 0.03 * configData.wScale;
    const innerTrimWidth = 0.01 * configData.wScale;

    const windowTrim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2, -height / 2 );
        shape.lineTo(  width / 2, unitY / 2 );
        shape.quadraticCurveTo(  width / 2.5, height / 2, 0,  height / 2);
        shape.quadraticCurveTo(  -width / 2.5, height / 2, -width / 2, unitY / 2 );
        shape.lineTo( -width / 2, -height / 2 );
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  width / 2 - trimWidth, -height / 2 + trimWidth);
        path.lineTo(  width / 2 - trimWidth, unitY / 2 );
        path.quadraticCurveTo(  width / 2.5 - trimWidth, height / 2 - trimWidth, 0,  height / 2 - trimWidth);
        path.quadraticCurveTo(  -width / 2.5 + trimWidth, height / 2 - trimWidth, -width / 2 + trimWidth, unitY / 2 );
        path.lineTo( -width / 2 + trimWidth, -height / 2 + trimWidth);
        path.closePath();

        shape.holes.push(path);

        return shape;
    }, [width, height]);

    const windowGlass = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2, -height / 2 );
        shape.lineTo(  width / 2, unitY / 2 );
        shape.quadraticCurveTo(  width / 2.5, height / 2, 0,  height / 2);
        shape.quadraticCurveTo(  -width / 2.5, height / 2, -width / 2, unitY / 2 );
        shape.lineTo( -width / 2, -height / 2 );
        shape.closePath();

        return shape
    });

    const windowInnerTrim = useMemo(() => {
        const shapes = [];
        const shape = new THREE.Shape();
        shape.moveTo(  unitX / 2, -height / 2 );
        shape.lineTo(  unitX / 2, unitY / 2 );
        shape.quadraticCurveTo(  unitX / 2.5, unitY * (2/2.4), 0,  unitY * (2/2.4));
        shape.quadraticCurveTo(  -unitX / 2.5, unitY * (2/2.4), -unitX / 2, unitY / 2 );
        shape.lineTo( -unitX / 2, -height / 2 );
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  unitX / 2 - innerTrimWidth, -height / 2 + innerTrimWidth);
        path.lineTo(  unitX / 2 - innerTrimWidth, unitY / 2 );
        path.quadraticCurveTo(  unitX / 2.5 - innerTrimWidth, unitY * (2/2.4) - innerTrimWidth, 0,  unitY * (2/2.4) - innerTrimWidth);
        path.quadraticCurveTo(  -unitX / 2.5 + innerTrimWidth, unitY * (2/2.4) - innerTrimWidth, -unitX / 2 + innerTrimWidth, unitY / 2 );
        path.lineTo( -unitX / 2 + innerTrimWidth, -height / 2 + innerTrimWidth);
        path.closePath();

        shape.holes.push(path);
        shapes.push(shape);

        const shape1 = new THREE.Shape();
        shape1.moveTo(-(width - innerTrimWidth) / 2, (unitY + innerTrimWidth) / 2);
        shape1.lineTo((width - innerTrimWidth) / 2, (unitY + innerTrimWidth) / 2);
        shape1.lineTo((width - innerTrimWidth) / 2,  (unitY -innerTrimWidth) / 2);
        shape1.lineTo(-(width - innerTrimWidth) / 2,  (unitY -innerTrimWidth) / 2);
        shapes.push(shape1);

        const shape2 = new THREE.Shape();
        shape2.moveTo(-(width - innerTrimWidth) / 2, -(unitY + innerTrimWidth) / 2);
        shape2.lineTo((width - innerTrimWidth) / 2, -(unitY + innerTrimWidth) / 2);
        shape2.lineTo((width - innerTrimWidth) / 2,  -(unitY -innerTrimWidth) / 2);
        shape2.lineTo(-(width - innerTrimWidth) / 2,  -(unitY -innerTrimWidth) / 2);
        shapes.push(shape2);

        const shape3 = new THREE.Shape();
        shape3.moveTo( -innerTrimWidth / 2, unitY * (2/2.4));
        shape3.lineTo( innerTrimWidth / 2, unitY * (2/2.4));
        shape3.lineTo( innerTrimWidth / 2, height / 2);
        shape3.lineTo( -innerTrimWidth / 2, height / 2);
        shapes.push(shape3);

        const shape4 = new THREE.Shape();
        shape4.moveTo( unitX / 2.8 - innerTrimWidth / 4, unitY * (2/2.7) + innerTrimWidth / 4);
        shape4.lineTo( unitX / 2.8 + innerTrimWidth / 4, unitY * (2/2.7) - innerTrimWidth / 4);
        shape4.lineTo( width / 2   + innerTrimWidth / 4, height / 2 - innerTrimWidth / 4);
        shape4.lineTo( width / 2   - innerTrimWidth / 4, height / 2 + innerTrimWidth / 4);
        shapes.push(shape4);


        const shape5 = new THREE.Shape();
        shape5.moveTo( -unitX / 2.8 - innerTrimWidth / 4, unitY * (2/2.7) - innerTrimWidth / 4);
        shape5.lineTo( -unitX / 2.8 + innerTrimWidth / 4, unitY * (2/2.7) + innerTrimWidth / 4);
        shape5.lineTo( -width / 2   + innerTrimWidth / 4, height / 2 + innerTrimWidth / 4);
        shape5.lineTo( -width / 2   - innerTrimWidth / 4, height / 2 - innerTrimWidth / 4);
        shapes.push(shape5);


        return shapes;
    }, [width, height]);

    return (
        <group>
            <mesh>
                <extrudeGeometry args={[windowTrim, extrudeSettings(thickness, 0.4, 0.4, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh position={[0, 0, 0.5]}>
                <extrudeGeometry args={[windowInnerTrim, extrudeSettings(thickness - 1, 0.4, 0.4, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh position={[0, 0, 1]}>
                <extrudeGeometry args={[windowGlass, extrudeSettings(thickness - 2)]} />
                <meshStandardMaterial color={'gray'} />
            </mesh>
        </group>
    )
}