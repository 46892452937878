
import { ExtLegTexture, extrudeSettings } from "../../../../../utils/Functions";
import { useEffect, useMemo, useRef } from "react";
import * as THREE from 'three'
import { useSelector } from "react-redux";
import { configData } from "../../../../../utils/Config";

export default function Pillar(props) {

    const pThickness = useSelector((state) => state.buildingReducer.params.pillarThickness);
    const exteriorLegType = useSelector((state) => state.buildingReducer.params.exteriorLegType);

    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorLegColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const legRef = useRef();

    const [textureP, isNormalMap] = ExtLegTexture(5, 0.2, Math.PI / 2);
    const textureS = ExtLegTexture(0.01, 0.007, Math.PI / 2);
    
    const pillarThickness = pThickness * configData.wScale;
    const supportThinkness = 0.25 * configData.wScale;

    const trimMatProps = {  
        map:isNormalMap? null: textureP,
        bumpMap:isNormalMap? null: textureP,
        normalMap:isNormalMap? textureP: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    useEffect(() => {
        legRef.current.needsUpdate = true;
    }, [isNormalMap]); 
    
    const support = useMemo(() => {
        const angle = Math.PI / 5;

        const hDistance = supportThinkness / Math.cos(Math.PI / 2 - angle);
        const wDistance = supportThinkness / Math.cos(angle);
        const height = 15;

        const shape = new THREE.Shape();
        shape.moveTo(0, -height + hDistance / 2);
        shape.lineTo(0, -height - hDistance / 2);
        shape.lineTo((Math.tan(angle) * height) + (wDistance / 2), 0);
        shape.lineTo((Math.tan(angle) * height) - (wDistance / 2), 0);
        shape.closePath();

        return shape;
    }, [props.height])

    return (
        <group {...props} >
            <mesh castShadow receiveShadow position={[0, props.height / 2, 0]}>
                <boxGeometry args={[pillarThickness, props.height, pillarThickness ]} />
                <meshPhongMaterial ref={legRef} {...trimMatProps} bumpScale={1}/>
            </mesh>
            
            {
                exteriorLegType === "type1" && <mesh castShadow receiveShadow position={[0, props.height, -supportThinkness/2]}>
                    <extrudeGeometry args={[support, extrudeSettings(supportThinkness)]} />
                    <meshPhongMaterial map={textureS} bumpMap={textureS} bumpScale={1}/>
                </mesh>
            }
            {
                exteriorLegType === "type1" && <mesh castShadow receiveShadow position={[props.placement == "corner" ?-supportThinkness/2: 0, props.height, props.placement == "corner" ? 0: supportThinkness/2]} rotation={[0, props.placement == "corner" ? Math.PI / 2: Math.PI, 0]}>
                <extrudeGeometry args={[support, extrudeSettings(supportThinkness)]} />
                <meshPhongMaterial map={textureS} bumpMap={textureS} bumpScale={1}/>
                </mesh>
            }
        </group>
    )
};