import { Accordion, AccordionSummary, AccordionDetails, IconButton, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../../assets/image";


export default function DoorWindowComponent({expanded, onChange}) {
    const dispath = useDispatch();
    const doorWindowsUIData = useSelector((state) => state.buildingReducer.params.doorWindowsUIData);

    const doubleDoor = doorWindowsUIData.find(e => e.category == "Double Door");
    const singleDoor = doorWindowsUIData.find(e => e.category == "Single Door");
    const singleWindow = doorWindowsUIData.find(e => e.category == "Single Window");

    const handleDoorClick = (e, data) => {
        dispath({
            type: "ADD_DOOR_WINDOW",
            value: data
        })
    }


    return (
        <>
            <Accordion expanded={expanded === "doorWindows"} onChange={(event, isExpanded) => onChange(isExpanded, "doorWindows")} elevation={0} disableGutters>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>
                    <Typography>DOOR & WINDOWS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container sx={{textAlign: "center"}} >
                        {
                            doubleDoor && (
                                    <Grid item xs={4}>
                                        <div style={{display: "flex", flexDirection:"column"}}>
                                            <IconButton onClick={(e) => handleDoorClick(e, doubleDoor)}>
                                                <img src={Images.doorWindowIcons["doubleDoor"]}/>                                    
                                            </IconButton>
                                            <Typography variant="body1">Double Door</Typography>
                                        </div>
                                    </Grid>
                                )
                        }      

                        
                        {
                            singleDoor && (
                                    <Grid item xs={4}>
                                        <div style={{display: "flex", flexDirection:"column"}}>
                                            <IconButton onClick={(e) => handleDoorClick(e, singleDoor)}>
                                                <img src={Images.doorWindowIcons["singleDoor"]}/>                                    
                                            </IconButton>
                                            <Typography variant="body1">Single Door</Typography>
                                        </div>
                                    </Grid>
                                )
                        } 

                        {
                            singleWindow && (
                                    <Grid item xs={4}>
                                        <div style={{display: "flex", flexDirection:"column"}}>
                                            <IconButton onClick={(e) => handleDoorClick(e, singleWindow)}>
                                                <img src={Images.doorWindowIcons["window"]}/>                                    
                                            </IconButton>
                                            <Typography variant="body1">Window</Typography>
                                        </div>
                                    </Grid>
                                )
                        }                  
                     </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}