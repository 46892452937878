import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useRef, useState } from "react"
import * as THREE from 'three'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { configData } from "../../../../../../../utils/Config"
import { ExtSidingTexture, extrudeSettings } from "../../../../../../../utils/Functions"
// import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
// import { Line2 } from 'three/examples/jsm/lines/Line2'
// import { extend } from "@react-three/fiber"

// extend({Line2, LineMaterial})

export default function FrontOuterWall() {

    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const sidingColor = useSelector((state) => state.buildingReducer.params.exteriorSidingColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular)
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const sidingTextureName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const sidePanelTabExpanded = useSelector((state) => state.buildingReducer.params.sidePanelTabExpanded);
    const activeWall = useSelector((state) => state.buildingReducer.params.activeWall);
    const [hover, setHover] = useState(false);
    const dispath = useDispatch();
    const meshRef = useRef();    

    const shouldHighlight = sidePanelTabExpanded === "doorWindows" && hover ? true: false;
    const shouldFocus = sidePanelTabExpanded === "doorWindows" && activeWall === "front" ? true: false;

    
    const isNormalMap = sidingTextureName.includes('Normal');
    const scaledHalfHeight = (height / 2) * configData.hScale;

    const sidingTextureObj = ExtSidingTexture();
    const wallMatProps = {  
        map:isNormalMap? null: sidingTextureObj,
        bumpMap:isNormalMap? null: sidingTextureObj,
        normalMap:isNormalMap? sidingTextureObj: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:sidingColor,        
        specular,
    }
    
    // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace
    isNormalMap && (wallMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); 

    const frontOuterWall = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale, -(height / 2 + baseHeight) * configData.hScale);
        shape.lineTo(-(width / 2) * configData.wScale, scaledHalfHeight);
        shape.lineTo((width / 2) * configData.wScale, scaledHalfHeight);
        shape.lineTo((width / 2) * configData.wScale, -(height / 2 + baseHeight) * configData.hScale);
        shape.closePath();

        const doors = doorWindowsData.filter((door) => door.wall === "front");
        doors.forEach((door) => {
            const path = new THREE.Path();
            path.moveTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.lineTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.closePath();
            shape.holes.push(path);
        })

        return shape;
    }, [length, width, height, porchSize, doorWindowsData]);

    
    useEffect(() => {
        meshRef.current.needsUpdate = true;
    }, [isNormalMap, transparent]); 
    

    const outline = useMemo(() => {
        return new LineGeometry().setPositions(frontOuterWall.getPoints().map((point) => [...point, 0]).flat(1));
    }, [width, height]);

    const events = useMemo(() => ({
        onPointerDown: (e) => {
            e.stopPropagation();
            e.face?.normal?.z > 0 && dispath({
                type: "SET_ACTIVE_WALL",
                value: "front"
            })
        },
        onPointerOver: (e) => {
            e.stopPropagation();
            e.face?.normal?.z >= 0 && setHover(true);
        },
        onPointerLeave: (e) => {
            e.stopPropagation();
            setHover(false);
        }
    }))

    return (
        <>
            <mesh {...events} name="front-outer-wall" castShadow receiveShadow rotation={[0, 0, 0]} position={[0, scaledHalfHeight, (length / 2 - porchSize) * configData.wScale]}>
                <extrudeGeometry args={[frontOuterWall, extrudeSettings(1)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={meshRef} {...wallMatProps} />
            </mesh>    
            {
                shouldFocus 
                && (<>
                    <line2 geometry={outline} position={[0, scaledHalfHeight, (length / 2 - porchSize) * configData.wScale]}>
                        <lineMaterial args={[{color: 'orange', transparent: true, depthTest: false, linewidth: 5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                    <line2 geometry={outline} position={[0, scaledHalfHeight, (length / 2 - porchSize) * configData.wScale]}>
                        <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 2.5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                </>)
            }
            {
                shouldHighlight && <line2 geometry={outline} position={[0, scaledHalfHeight, (length / 2 - porchSize) * configData.wScale]}>
                    <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 1, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                </line2>
            }   
        </>
    )
}