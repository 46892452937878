import { useSelector } from "react-redux"
import { useEffect, useMemo, useRef } from "react"
import * as THREE from 'three'
import { configData } from "../../../../../utils/Config";
import { ExtSidingTexture, extrudeSettings } from "../../../../../utils/Functions";


export default function FrontBottomRoof() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const sidingColor = useSelector((state) => state.buildingReducer.params.exteriorSidingColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular)
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const sidingTextureName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture)
    const isNormalMap = sidingTextureName.includes('Normal');
    const meshRef = useRef();

    const alpha = Math.atan(roofPitch / 12);
    const wallThinkness = 1;
    const wallThinknessRoofGap = 6 / configData.wScale;
    const h1 = wallThinknessRoofGap / Math.cos(alpha);
    const h4 = (roofPitch / 12) * (wallThinkness / configData.wScale);

    const frontBottomRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale -wallThinkness, 0);
        shape.lineTo(-(width / 2) * configData.wScale -wallThinkness, (porchSize) * configData.wScale);
        shape.lineTo((width / 2) * configData.wScale +wallThinkness, (porchSize) * configData.wScale);
        shape.lineTo((width / 2) * configData.wScale +wallThinkness, 0);
        shape.closePath();
        return shape;
    }, [length, width, porchSize]);

    
    useEffect(() => {
        meshRef.current.needsUpdate = true;
    }, [isNormalMap]); 
    
    const sidingTextureObj = ExtSidingTexture();
    const wallMatProps = {  
        map:isNormalMap? null: sidingTextureObj,
        bumpMap:isNormalMap? null: sidingTextureObj,
        normalMap:isNormalMap? sidingTextureObj: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:sidingColor,        
        specular,
    }
    
    isNormalMap && (wallMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace

    return (
        <group name="front-bottom-roof" position={[0, (h1 - h4) * configData.hScale, (length / 2 - porchSize) * configData.wScale]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh castShadow receiveShadow>
                <extrudeGeometry args={[frontBottomRoof, extrudeSettings((h1 - h4) * configData.hScale)]} />
                <meshPhongMaterial ref={meshRef} {...wallMatProps} />
            </mesh>
        </group>
    )
}