import Images from "../assets/image"

export const configData = {
    wScale: 24,
    hScale: 24
}

const colors = [
    {
        name: "color1",
        code: "#9a8974"
    },
    {
        name: "color2",
        code: "#a5a394"
    },
    {
        name: "Superwhite - SW 6995 Super Paint",
        code: "rgb(232, 234, 234)"
    },
    {
        name: "Popular Gray - SW 6071 Super Paint",
        code: "rgb(212, 204, 195)"
    },
    {
        name: "Taupe Tone - SW 7633 Super Paint",
        code: "rgb(173, 160, 144)"
    },
    {
        name: "Sandy Ridge - SW 7535 Super Paint",
        code: "rgb(161, 142, 119)"
    },
    {
        name: "Evergreen Fog - SW 9130 Super Paint",
        code: "rgb(149, 151, 138)"
    },
    {
        name: "Summit Gray - SW 7669 Super Paint",
        code: "rgb(149, 148, 145)"
    },
    {
        name: "Backdrop - SW 7025 Super Paint",
        code: "rgb(134, 122, 111)"
    },
    {
        name: "Rosemary - SW 6187 Super Paint",
        code: "rgb(100, 105, 92)"
    },
    {
        name: "Peppercorn - SW 7674 Super Paint",
        code: "rgb(88, 88, 88)"
    },
    {
        name: "French Roast - SW 6069 Super Paint",
        code: "rgb(79, 52, 38)"
    },
    {
        name: "Fired Brick - SW 6335 Super Paintt",
        code: "rgb(131, 56, 42)"
    },
    {
        name: "Crabby Apple - SW 7592 Super Paint",
        code: "rgb(117, 53, 49)"
    },
    {
        name: "Urbane Bronze - SW 7048 Super Paint",
        code: "rgb(84, 80, 74)"
    },
    {
        name: "Naval - SW 6244 Super Paint",
        code: "rgb(47, 61, 76)"
    },
    {
        name: "Barn Red",
        code: "rgb(175, 93, 80)"
    },
    {
        name: "Black",
        code: "rgb(20, 18, 12)"
    },
    {
        name: "Burgundy",
        code: "rgb(94, 61, 53)"
    },
    {
        name: "Burnished Slate",
        code: "rgb(84, 80, 69)"
    },
    {
        name: "Charcoal",
        code: "rgb(88, 92, 91)"
    },
    {
        name: "Clay",
        code: "rgb(189, 179, 161)"
    },
    {
        name: "Cocoa Brown",
        code: "rgb(108, 81, 60)"
    },
    {
        name: "Forest Green",
        code: "rgb(26, 72, 57)"
    },
    {
        name: "Hawaiian Blue",
        code: "rgb(106, 145, 162)"
    },
    {
        name: "Light Gray",
        code: "rgb(178, 179, 173)"
    },
    {
        name: "Mocha Tan",
        code: "rgb(206, 181, 151)"
    },
    {
        name: "Patriot Red",
        code: "rgb(179, 51, 37)"
    },
    {
        name: "Pebble Beige",
        code: "rgb(228, 219, 203)"
    },
    {
        name: "Pure White",
        code: "rgb(229, 230, 228)"
    },
    {
        name: "White (Standard)",
        code: "rgb(255, 255, 255)"
    },
    {
        name: "Brown",
        code: "rgb(63, 52, 46)"
    },
    {
        name: "Black (Standard)",
        code: "rgb(0, 0, 0)"
    },
]

export const getBuildingData = () =>{
    return {
        length: 24,
        width: 12,
        height: 7,
        minHeight: 7,
        maxHeight: 12,
        minPitch: 1,
        maxPitch: 8,
        roofPitch: 5,
        porchSize: 5,
        minPorchSize: 4,
        maxPorchSize: 10,

        exteriorRoofTexture: "Weathered Wood",
        exteriorSidingTexture: "Siding Wood Normal",
        exteriorLegTexture: "Leg Normal",
        exteriorTrimTexture: "Leg Normal",
        exteriorFloorTexture: "Floor Wood Gray",
        interiorRoofTexture: "Interior LP",
        interiorSidingTexture: "Interior LP",
        interiorFloorTexture: "Interior LP",

        exteriorRoofColor: "rgb(255, 255, 255)",
        exteriorSidingColor: "rgb(161, 142, 119)",
        exteriorLegColor: "rgb(232, 234, 234)",
        exteriorTrimColor: "rgb(232, 234, 234)",
        exteriorFloorColor: "rgb(161, 142, 119)",
        interiorRoofColor: "#9a8974",
        interiorSidingColor: "#a5a394",
        interiorFloorColor: "#a5a394",

        colors: {
            exterior: {
                roof: colors,
                siding: colors,
                trim: colors,
                leg: colors,
                floor: colors,
            },
            interior: {
                roof: colors,
                siding: colors,
                floor: colors
            }
        },

        materials: {
            exterior: {
                roof: Images.exteriorRoof,
                siding: Images.exteriorSiding,
                trim: Images.exteriorLeg, 
                leg: Images.exteriorLeg,
                floor: Images.exteriorFloor
            },
            interior: {
                roof: Images.interior,
                siding: Images.interior,
                floor: Images.interior
            }
        },
        doorWindowsUIData:  [
            {
                id: 1,
                type: "door",
                category: "Double Door",
                name: "Colonist Double Door",
                value: "colonistdoubledoor",
                width: 6,
                height: 6,
                unit: "ft",
            }, 
            {
                id: 2,
                type: "door",
                category: "Double Door",
                name: "Nine Lite Double Door",
                value: "ninelitedoubledoor",
                width: 6,
                height: 6,
                unit: "ft",
            }, 
            {
                id: 3,
                type: "door",
                category: "Double Door",
                name: "Eleven Lite Double Door",
                value: "elevenlitedoubledoor",
                width: 6,
                height: 6,
                unit: "ft",
            }, 
            {
                id: 4,
                type: "door",
                category: "Single Door",
                name: "Nine Lite Door",
                value: "ninelitedoor",
                width: 3,
                height: 6,
                unit: "ft",
            },
            {
                id: 5,
                type: "door",
                category: "Single Door",
                name: "Colonist Door",
                value: "colonistdoor",
                width: 3,
                height: 6,
                unit: "ft",
            },
            {
                id: 6,
                type: "door",
                category: "Single Door",
                name: "Eleven Lite Door",
                value: "elevenlitedoor",
                width: 3,
                height: 6,
                unit: "ft",
            },
            {
                id: 7,
                type: "window",
                category: "Single Window",
                name: "Four Lite Colonial Window",
                value: "fourlitecolonialwindow",
                width: 2,
                height: 3,
                unit: "ft",
            },
            {
                id: 8,
                type: "window",
                category: "Single Window",
                name: "Nine Lite Window",
                value: "ninelitewindow",
                width: 2,
                height: 3,
                unit: "ft",
            },
        ],   
        doorWindowsData: [
            {
                "wall":"front",
                "id":4,
                "type":"door",
                "category":"Single Door",
                "name":"Nine Lite Door",
                "value":"ninelitedoor",
                "width":3,
                "height":6,
                "unit":"ft",
                "posX":72.40907377361398,
                "posY":72
            },
            {
                "wall":"front",
                "id":7,
                "type":"window",
                "category":"Single Window",
                "name":"Four Lite Colonial Window",
                "value":"fourlitecolonialwindow",
                "width":2,
                "height":3,
                "unit":"ft",
                "posX":-61.46074295854581,
                "posY":97.8040095666196
            }
        ],
        buildingStyleImages: Images.buildingStyleImages,
        buildingSizes: [
            "8x10", "8x12", "8x14","8x16","8x18","8x20",
            "10x10", "10x12", "10x14","10x16","10x18","10x20",
            "12x12", "12x14","12x16","12x18","12x20", "12x24","12x28","12x32",
            "14x24", "14x28","14x32","14x36","14x40",
            "16x24", "16x28","16x32","16x36","16x40",
        ]
    }
}