import { useEffect, useMemo, useRef } from "react"
import * as THREE from 'three'
import { ExtTrimTexture, extrudeSettings } from "../../../../utils/Functions";
import { configData } from "../../../../utils/Config";
import { useSelector } from "react-redux";
import Window from "./Window";

export default function FourLiteColonialWindow({width, height, trimWidth, thickness, ...props}) {
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const [texture, isNormalMap] = ExtTrimTexture();
    const trimRef = useRef();

    const windowThickness = trimWidth/2;
    const holeWidth = (width - 2 * windowThickness);
    const holeHeight = (height - 2 * windowThickness);
    const innerTrimWidth = 0.05 * configData.wScale;

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    useEffect(() => {
        trimRef.current.needsUpdate = true;
    }, [isNormalMap]); 

    const windowTrim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2 + trimWidth, -height / 2 - trimWidth);
        shape.lineTo(  width / 2 + trimWidth, height / 2 + trimWidth);
        shape.lineTo( -width / 2 - trimWidth, height / 2 + trimWidth);
        shape.lineTo( -width / 2 - trimWidth, -height / 2 - trimWidth);
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  width / 2, -height / 2 );
        path.lineTo(  width / 2, height / 2 );
        path.lineTo( -width / 2, height / 2 );
        path.lineTo( -width / 2, -height / 2 );
        path.closePath();

        shape.holes.push(path);
        return shape;
    }, [width, height]);

    const window = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2, -height / 2 );
        shape.lineTo(  width / 2, height / 2 );
        shape.lineTo( -width / 2, height / 2 );
        shape.lineTo( -width / 2, -height / 2 );
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  width / 2 - windowThickness, -height / 2 + windowThickness);
        path.lineTo(  width / 2 - windowThickness, height / 2 - windowThickness);
        path.lineTo( -width / 2 + windowThickness, height / 2 - windowThickness);
        path.lineTo( -width / 2 + windowThickness, -height / 2 + windowThickness);
        path.closePath();

        shape.holes.push(path);
        return shape;
    }, [width, height]);


    return (
        <group {...props}>
            <mesh name="outer-trim" >
                <extrudeGeometry args={[windowTrim, extrudeSettings(thickness, 0, 0, 0, 2)]} />
                <meshStandardMaterial ref={trimRef} {...trimMatProps} />
            </mesh>
            <mesh>
                <extrudeGeometry args={[window, extrudeSettings(thickness, 0, 0, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>
            <Window width={holeWidth} height={holeHeight/2} trimWidth={innerTrimWidth} thickness={thickness/3} position={[ 0, holeHeight/4, thickness * (1/2)]} />
            <Window width={holeWidth} height={holeHeight/2} trimWidth={innerTrimWidth} thickness={thickness/3} position={[ 0, -holeHeight/4, thickness/6]} />
        </group>
    )
}