import { useEffect, useMemo, useRef } from "react"
import { ExtTrimTexture, extrudeSettings } from "../../../../utils/Functions";
import * as THREE from 'three'
import HoleModel from "../Common/ColonistHoleModel";
import { useSelector } from "react-redux";

export default function ColonistDoor({width, height, trimWidth, ...props}) {
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const [texture, isNormalMap] = ExtTrimTexture();
    const trimRef = useRef();

    const doorThickness = 5;

    const doorBevel = 0.4;

    const unitX = width / 5;
    const unitY = height / 13;

    const marginX = unitX;
    const marginY = unitY;

    const largeHoleHeight = 4 * unitY;
    const smallHoleHeight = unitY;
    const holeWidth = unitX * 1.1;
    const holeDistX = (unitX / 2);
    const holePosX = holeDistX + holeWidth / 2;

    const bottomHolePosY = -height / 2 + marginY + largeHoleHeight / 2;
    const midHolePosY = bottomHolePosY + largeHoleHeight + marginY;
    const upholePosY = midHolePosY + largeHoleHeight / 2 + marginY + smallHoleHeight / 2;
    const holePositions = [bottomHolePosY, midHolePosY];

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    useEffect(() => {
        trimRef.current.needsUpdate = true;
    }, [isNormalMap]); 


    const trim = useMemo(() => {
        const actualDoorWidth = width + 2 * trimWidth;
        const shape = new THREE.Shape();
        shape.moveTo( -width / 2, -height / 2);
        shape.lineTo( -width / 2, height / 2);
        shape.lineTo( width / 2, height / 2);
        shape.lineTo( width / 2, -height / 2);
        shape.lineTo( actualDoorWidth / 2, -height / 2);
        shape.lineTo( actualDoorWidth / 2, height / 2 + trimWidth);
        shape.lineTo( -actualDoorWidth / 2, height / 2 + trimWidth);
        shape.lineTo( -actualDoorWidth / 2, -height / 2);
        shape.closePath();
        return shape;
    }, [ width,  height,  trimWidth]);


    const door = useMemo(() => {

        const shape = new THREE.Shape();
        shape.moveTo(  (width - doorBevel)/ 2, -(height - doorBevel) / 2);
        shape.lineTo(  (width - doorBevel)/ 2, (height - doorBevel) / 2);
        shape.lineTo( -(width - doorBevel)/ 2, (height - doorBevel) / 2);
        shape.lineTo( -(width - doorBevel)/ 2, -(height - doorBevel) / 2);
        shape.closePath();

        for (let i = 0; i < 2; i ++) {
            const holePos = holePositions[i];
            for (let j = 0; j < 2; j ++) {
                const upHole = new THREE.Path();
                upHole.moveTo(Math.pow(-1, j) * (holePosX + holeWidth / 2), holePos - largeHoleHeight / 2);
                upHole.lineTo(Math.pow(-1, j) * (holePosX + holeWidth / 2), holePos + largeHoleHeight / 2);
                upHole.lineTo(Math.pow(-1, j) * (holePosX - holeWidth / 2), holePos + largeHoleHeight / 2);
                upHole.lineTo(Math.pow(-1, j) * (holePosX - holeWidth / 2), holePos - largeHoleHeight / 2);
                upHole.closePath()
                shape.holes.push(upHole);
            }  
        }

        for (let j = 0; j < 2; j ++) {
            const upHole = new THREE.Path();
            upHole.moveTo(Math.pow(-1, j) * (holePosX + holeWidth / 2), upholePosY - smallHoleHeight / 2);
            upHole.lineTo(Math.pow(-1, j) * (holePosX + holeWidth / 2), upholePosY + smallHoleHeight / 2);
            upHole.lineTo(Math.pow(-1, j) * (holePosX - holeWidth / 2), upholePosY + smallHoleHeight / 2);
            upHole.lineTo(Math.pow(-1, j) * (holePosX - holeWidth / 2), upholePosY - smallHoleHeight / 2);
            upHole.closePath()
            shape.holes.push(upHole);
        }  

        return shape;
    }, [ width,  height,  trimWidth]);


    return (
        <group {...props}>
            <mesh name="outer-trim">
                <extrudeGeometry args={[trim, extrudeSettings(doorThickness)]}/>
                <meshStandardMaterial  ref={trimRef} {...trimMatProps} />
            </mesh> 
            <mesh>
                <extrudeGeometry args={[door, extrudeSettings(doorThickness, doorBevel/2, doorBevel/2, 0, 1)]}/>
                <meshStandardMaterial />
            </mesh>

            {Array(2).fill(0).map((item, index) => <HoleModel 
                key={index}
                position={[Math.pow(-1, index) * (holeDistX + holeWidth/2), bottomHolePosY, 0]} 
                width={holeWidth} 
                height={largeHoleHeight} 
                thickness={doorThickness}
            />)} 

            {Array(2).fill(0).map((item, index) => <HoleModel 
                key={index}
                position={[Math.pow(-1, index) * (holeDistX + holeWidth/2), midHolePosY, 0]} 
                width={holeWidth} 
                height={largeHoleHeight} 
                thickness={doorThickness}
            />)} 

            {Array(2).fill(0).map((item, index) => <HoleModel 
                key={index}
                position={[Math.pow(-1, index) * (holeDistX + holeWidth/2), upholePosY, 0]} 
                width={holeWidth} 
                height={smallHoleHeight} 
                thickness={doorThickness}
            />)} 
        </group>
    )
}