import background from "./background.jpg";
import background1 from "./background1.jpg";
import grass from "./grass.jpg"
import floor from "./BuildingMaterials/interior-osb.png"
import porch from './BuildingMaterials/floor-wood2.png'
import outerWallImg from './BuildingMaterials/siding-horizontal1.jpg'
import roof from './BuildingMaterials/roof7.png'
import trim from './BuildingMaterials/leg-wood3.png'

//buildng style images
import classicGable from './BuildingStyles/classic-gable.png'
import classicGablePorch from './BuildingStyles/classic-gable-porch.png'
import Skillion from './BuildingStyles/skillion.png'
import ranchUtility from './BuildingStyles/ranch-utility.png'


//building material images
//Exterior images
//Roof
import burntSienna from './BuildingMaterials/burnt-sienna.png'
import chateauGreen from './BuildingMaterials/chateau-green.png'
import driftWood from './BuildingMaterials/drift-wood.png'
import georgeTownGray from './BuildingMaterials/george-town-gray.png'
import graniteGray from './BuildingMaterials/granite-gray.png'
import heatherBlend from './BuildingMaterials/heather-blend.png'
import hunterGreen from './BuildingMaterials/hunter-green.png'
import moireBlack from './BuildingMaterials/moire-black.png'
import pewter from './BuildingMaterials/pewter.png'
import resawnShake from './BuildingMaterials/resawn-shake.png'
import silverBirch from './BuildingMaterials/silver-birch.png'
import weatheredWood from './BuildingMaterials/weathered-wood.png'
import roofMetalHorizontal from './BuildingMaterials/roof-metal-horizontal.svg'
import roofMetalVertical from './BuildingMaterials/roof-metal-vertical.svg'

//siding
import sidingLpNormal from './BuildingMaterials/siding-lp-normal.png'
import sidingVinylNormal from './BuildingMaterials/siding-vinyl-normal.png'
import sidingWoodNormal from './BuildingMaterials/siding-wood-normal.png'
import sidingMetalNormal from './BuildingMaterials/siding-metal-normal.png'
import sidingHorizontal from './BuildingMaterials/siding-horizontal.png'
import sidingVertical from './BuildingMaterials/siding-vertical.jpg'
import sidingHorizontal1 from './BuildingMaterials/siding-horizontal1.jpg'
import sidinglp from './BuildingMaterials/siding-lp.png'

//leg
import legWood from './BuildingMaterials/leg-wood.png'
import legWood2 from './BuildingMaterials/leg-wood2.png'
import legWood3 from './BuildingMaterials/leg-wood3.png'
import legNormal from './BuildingMaterials/leg-normal.png'
import legNormal2 from './BuildingMaterials/leg-normal2.png'

//floor
import floorMetal from './BuildingMaterials/floor-metal.png'
import floorWood from './BuildingMaterials/floor-wood.png'
import floorWood2 from './BuildingMaterials/floor-wood2.png'
import floorWoodGray from './BuildingMaterials/floor-wood-gray.png'

//interior
import interiorLp from './BuildingMaterials/interior-lp.png'
import interiorOsb from './BuildingMaterials/interior-osb.png'
import interiorRadiantBarrier from './BuildingMaterials/interior-radiant-barrier.png'

//door windows
import doubleDoor from './icons/icon-doubledoor.png'
import singleDoor from './icons/icon-singledoor.png'
import window from './icons/icon-window.png'


const Images = {
    background,
    background1,
    grass,
    floor,
    porch,
    outerWallImg,
    roof,
    trim,
    doorWindowIcons: {
        doubleDoor,
        singleDoor,
        window,
    },
    buildingStyleImages: [
        {   
            img: classicGable,
            name: "Classic Gable"
        },
        {
            img: classicGablePorch,
            name: "Classic Gable Porch"
        },
        {
            img: Skillion,
            name: "Skillion"
        },
        {
            img: ranchUtility,
            name: "Utility Ranch"
        }
    ],

    exteriorRoof: [
        {
            img: burntSienna,
            name: "Burnt Sienna"
        },
        {
            img: chateauGreen,
            name: "Chateau Green"
        },
        {
            img: driftWood,
            name: "Drift Wood"
        },
        {
            img: georgeTownGray,
            name: "George Town Gray"
        },
        {
            img: graniteGray,
            name: "Granite Gray"
        },
        {
            img: heatherBlend,
            name: "Heather Blend"
        },
        {
            img: hunterGreen,
            name: "Hunter Green"
        },
        {
            img: moireBlack,
            name: "Moire Black"
        },
        {
            img: pewter,
            name: "Pewter"
        },
        {
            img: resawnShake,
            name: "Resawn Shake"
        },
        {
            img: silverBirch,
            name: "Silver Birch"
        },
        {
            img: weatheredWood,
            name: "Weathered Wood"
        },
        // {
        //     img: roofMetalHorizontal,
        //     name: "Roof Metal Horizontal"
        // },
        // {
        //     img: roofMetalVertical,
        //     name: "Roof Metal Vertical"
        // }
    ],
    exteriorSiding: [
        {
            img: sidingLpNormal,
            name: "Siding LP Normal"
        },
        {
            img: sidingVinylNormal,
            name: "Siding Vinyl Normal"
        },
        {
            img: sidingWoodNormal,
            name: "Siding Wood Normal"
        },
        {
            img: sidingMetalNormal,
            name: "Siding Metal Normal"
        },
        {
            img: sidingHorizontal,
            name: "Siding Horizontal"
        },
        {
            img: sidingVertical,
            name: "Siding Vertical"
        },
        {
            img: sidingHorizontal1,
            name: "Siding Horizontal1"
        },
        {
            img: sidinglp,
            name: "Siding LP"
        }
    ],
    exteriorLeg: [
        {
            img: legWood,
            name: "Leg Wood"
        },
        {
            img: legWood2,
            name: "Leg Wood2"
        },
        {
            img: legWood3,
            name: "Leg Wood3"
        },
        {
            img: legNormal,
            name: "Leg Normal"
        },
        {
            img: legNormal2,
            name: "Leg Normal2"
        },
        {
            img: floorWood,
            name: "Floor Wood"
        },
        {
            img: floorWood2,
            name: "Floor Wood2"
        },
    ],  
    exteriorTrim: [
        {
            img: legWood,
            name: "Leg Wood"
        },
        {
            img: legWood2,
            name: "Leg Wood2"
        },
        {
            img: legWood3,
            name: "Leg Wood3"
        },
        {
            img: legNormal,
            name: "Leg Normal"
        },
        {
            img: legNormal2,
            name: "Leg Normal2"
        },
        {
            img: floorWood,
            name: "Floor Wood"
        },
    ], 
    exteriorFloor: [      
        {
            img: floorMetal,
            name: "Floor Metal"
        },
        {
            img: floorWood,
            name: "Floor Wood"
        },
        {
            img: floorWood2,
            name: "Floor Wood2"
        },
        {
            img: floorWoodGray,
            name: "Floor Wood Gray"
        }
    ],
    interior: [
        {
            img: interiorLp,
            name: "Interior LP"
        },
        {
            img: interiorOsb,
            name: "Interior OSB"
        },
        {
            img: interiorRadiantBarrier,
            name: "Interior Radiant Barrier"
        }
    ]
}


export default Images