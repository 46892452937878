import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../utils/Config";
import { ExtSidingTexture, extrudeSettings } from "../../../../../utils/Functions";

export default function MainMiddleRoof() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);    
    const sideExt = useSelector((state) => state.buildingReducer.params.sideExtension);    
    const endExt = useSelector((state) => state.buildingReducer.params.endExtension);    
    const sidingColor = useSelector((state) => state.buildingReducer.params.exteriorSidingColor) ;
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const sidingTextureName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture)
    const isNormalMap = sidingTextureName.includes('Normal');
    const lRoofRef = useRef();
    const rRoofRef = useRef();

    // const thickness = 0.1;                                                  // 2 /  configData.wScale(20)
    const wallThinknessRoofGap = 6 / configData.wScale; //0.25;                    //gap from height h
    const alpha = Math.atan(roofPitch / 12);        
    const extendedWidth = (width / 2 +  (Math.cos(alpha) * sideExt))        //extended width
    const extendedLength = length + 2 * endExt;
    const h = (roofPitch / 12) * (width / 2) * configData.hScale;
    const h1 = wallThinknessRoofGap / Math.cos(alpha) * configData.hScale;
    const h2 = (roofPitch / 12) * extendedWidth;
    const slantheight =  Math.sqrt(((extendedWidth * configData.wScale) **2 + (h2 * configData.hScale) **2));
    const alphaScaled = Math.atan((h2 * configData.hScale) / (extendedWidth * configData.wScale));

    const middleLeftRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, extendedLength * configData.wScale);
        shape.lineTo(-slantheight, extendedLength * configData.wScale);
        shape.lineTo(-slantheight, 0);
        shape.closePath();
        return shape;
    }, [extendedWidth, extendedLength, roofPitch]);

    const middleRightRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, extendedLength * configData.wScale);
        shape.lineTo(slantheight, extendedLength * configData.wScale);
        shape.lineTo(slantheight, 0);
        shape.closePath();
        return shape;
    }, [extendedWidth, extendedLength, roofPitch]);

    useEffect(() => {
        lRoofRef.current.needsUpdate = true;
        rRoofRef.current.needsUpdate = true;
    }, [isNormalMap, transparent]); 

    const texture = ExtSidingTexture();
    const wallMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:sidingColor,        
        specular,
    }
    
    isNormalMap && (wallMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace


    return (
        <group name="middle-roof">
            <mesh castShadow receiveShadow name="middle-left-roof" position={[ 0, h + h1, -(extendedLength / 2) * configData.wScale]} rotation={[Math.PI/2, alphaScaled, 0]}>
                <extrudeGeometry args={[middleLeftRoof, extrudeSettings(0.01)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={lRoofRef} {...wallMatProps}/>
            </mesh>

            <mesh castShadow receiveShadow name="middle-right-roof" position={[ 0, h + h1, -(extendedLength / 2) * configData.wScale]} rotation={[Math.PI/2, -alphaScaled, 0]}>
                <extrudeGeometry args={[middleRightRoof, extrudeSettings(0.01)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={rRoofRef} {...wallMatProps}/>
            </mesh>
        </group>
    )
}