import Images from "../assets/image"
import { useSelector } from "react-redux"
import { useMemo } from "react";
import * as THREE from 'three'
import { useThree } from "@react-three/fiber";

let texLoader = new THREE.TextureLoader();

export const convertToFeetInches = (number) => {
    const truncatedNumbers = Math.abs(number).toFixed(2).split('.');
    const feet = truncatedNumbers[0];
    const inch = (truncatedNumbers[1] * 12 / 100).toFixed();
    const mesurement = feet + "\'" + (inch == '0' ? '': (inch + '"'));
    return mesurement;
}

export const getTexture = (gl, img, repX, repY, rotate = 0) => {
    const tex = texLoader.load(img);
    tex.anisotropy = Math.min(gl.capabilities.getMaxAnisotropy());
    // tex.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace
    // tex.colorSpace=THREE.LinearSRGBColorSpace;
    tex.wrapT = THREE.RepeatWrapping;
    tex.wrapS = THREE.RepeatWrapping;
    tex.repeat.set(repX, repY);
    tex.rotation = rotate;
    return tex;
}



export const extrudeSettings = (depth, bevelThickness = 0, bevelSize = 0, bevelOffset = 0, bevelSegments = 1) => {
    return {
        steps: 1,
        depth,
        bevelEnabled: true,
        bevelThickness,
        bevelSize,
        bevelOffset,
        bevelSegments,

    }
}


export const ExtRoofTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.exteriorRoofTexture)
    const images = useSelector((state) => state.buildingReducer.params.exteriorRoofImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const roofTextureObj = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.0138, 0.0385, Math.PI/2);
    }, [imgName]);


    return roofTextureObj;
}
export const ExtSidingTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture);
    const images = useSelector((state) => state.buildingReducer.params.exteriorSidingImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const innerWallTexture = useMemo(() => {
        //texture-detail: texture has 4 strip & each 1 considered as 8 inch. so total texture wrapping is 32 inch = 64 unit i.e 1/64 = 0.03125
        return getTexture(gl, imageObj.img, 1/64, 1/64);          
    }, [imageObj.img]);


    return innerWallTexture;
}

export const ExtTrimTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.exteriorTrimTexture);
    const images = useSelector((state) => state.buildingReducer.params.exteriorTrimImages);
    const isNormalMap = imgName.includes('Normal');
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const trimTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.05, 0.05);
    }, [imgName]);


    return [trimTexture, isNormalMap];
}

export const ExtLegTexture = (repeatX, repeatY, rotation) => {
    const imgName = useSelector((state) => state.buildingReducer.params.exteriorLegTexture);
    const images = useSelector((state) => state.buildingReducer.params.exteriorLegImages);
    const isNormalMap = imgName.includes('Normal');
    const imageObj = images.find((item) => item.name === imgName);

    const {gl} = useThree();

    const porchTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, repeatX, repeatY, rotation);
    }, [imageObj]);

    return [porchTexture, isNormalMap];
 }

export const ExtFloorTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.exteriorFloorTexture);
    const images = useSelector((state) => state.buildingReducer.params.exteriorFloorImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const porchTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.01, 0.01, Math.PI/2);
    }, [imgName]);

    return porchTexture;
 }

export const IntRoofTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.interiorRoofTexture);
    const images = useSelector((state) => state.buildingReducer.params.interiorRoofImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const innerWallTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.01, 0.01);
    }, [imgName]);


    return innerWallTexture;
}

export const IntSidingTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.interiorSidingTexture);
    const images = useSelector((state) => state.buildingReducer.params.interiorSidingImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const innerWallTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.01, 0.01);
    }, [imgName]);


    return innerWallTexture;
}

export const IntFloorTexture = () => {
    const imgName = useSelector((state) => state.buildingReducer.params.interiorFloorTexture);
    const images = useSelector((state) => state.buildingReducer.params.interiorFloorImages);
    const imageObj = images.find((item) => item.name === imgName);
    const {gl} = useThree();

    const innerWallTexture = useMemo(() => {
        return getTexture(gl, imageObj.img, 0.01, 0.01);
    }, [imgName]);


    return innerWallTexture;
}






