import FrontWall from "./FrontWall"
import BackWall from "./BackWall"

export default function EndWall() {
    return (
        <group>
            <FrontWall />
            <BackWall />
        </group>
    )
}