import { useSelector } from "react-redux"
import { configData } from "../../../../utils/Config";
import MainTopRoof from "./MainTopRoof";
import MainMiddleRoof from "./MainMiddleRoof"
import MainInnerRoof from "./MainInnerRoof";
import MainRoofTrim from "./MainRoofTrim";
import FrontBottomRoof from "./FrontBottomRoof/Index";
import FrontRoof from "./FrontRoof";

export default function Roof() {

    const height = useSelector((state) => state.buildingReducer.params.height);

    return (
        <group name="roof" position={[0, (height) * configData.hScale, 0]}>
            <MainTopRoof />
            <MainMiddleRoof/>
            <MainInnerRoof />            
            <MainRoofTrim />
            <FrontBottomRoof />
            <FrontRoof />
        </group>
    )
}