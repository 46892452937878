import { useMemo } from 'react';
import * as THREE from 'three'
import { extrudeSettings } from '../../../../../utils/Functions';


export default function Window({width, height, trimWidth, thickness, ...props}) {

    const innerWindowBorder = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-width/2, -height /2);
        shape.lineTo(-width/2, height /2);
        shape.lineTo( width/2, height /2);
        shape.lineTo( width/2, -height /2);
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo( (width / 2 - trimWidth), -(height /2 - trimWidth));
        path.lineTo( (width / 2 - trimWidth),  (height /2 - trimWidth));
        path.lineTo(-(width / 2 - trimWidth),  (height /2 - trimWidth));
        path.lineTo(-(width / 2 - trimWidth), -(height /2 - trimWidth));
        path.closePath();

        shape.holes.push(path);
        return shape;
    }, [width, height, trimWidth])

    const innerWindowTrim = useMemo(() => {
        const shapes = [];

        const shape1 = new THREE.Shape();
        shape1.moveTo(-(width / 2 - trimWidth), trimWidth / 2);
        shape1.lineTo( (width / 2 - trimWidth), trimWidth / 2);
        shape1.lineTo( (width / 2 - trimWidth), -trimWidth / 2);
        shape1.lineTo(-(width / 2 - trimWidth), -trimWidth / 2);
        shape1.closePath();
        shapes.push(shape1);

        const shape2 = new THREE.Shape();
        shape2.moveTo(  trimWidth / 2, -(height / 2 - trimWidth));
        shape2.lineTo(  trimWidth / 2,  (height / 2 - trimWidth));
        shape2.lineTo( -trimWidth / 2,  (height / 2 - trimWidth));
        shape2.lineTo( -trimWidth / 2, -(height / 2 - trimWidth));
        shape2.closePath();
        shapes.push(shape2);

        return shapes;
    }, [width, height, trimWidth]);

    return (
        <group {...props}>
            <mesh>
                <extrudeGeometry args={[innerWindowBorder, extrudeSettings(thickness, 0, 0, 0, 1)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh>
                <extrudeGeometry args={[innerWindowTrim, extrudeSettings(thickness, 0, 0, 0, 1)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh position={[0, 0, thickness/2]}>
                <boxGeometry args={[width - 2 * trimWidth, height - 2 * trimWidth, thickness/10]} />
                <meshStandardMaterial color={'gray'} />
            </mesh>
        </group>
    )
}