import { Accordion, AccordionSummary, AccordionDetails, Grid, Box, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";


export default function StyleComponent({expanded, onChange}) {
    const buildingStyleImages = useSelector((state) => state.buildingReducer.constants.buildingStyleImages);

    return (
        <>
            <Accordion expanded={expanded === "style"} onChange={(event, isExpanded) => onChange(isExpanded, "style")} elevation={0} disableGutters>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>
                    <Typography>STYLE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                     <Grid container sx={{textAlign: "center"}} >
                        {buildingStyleImages.map((item, index) => {
                            return (
                                <Grid key={index} item xs={4}>
                                    <div style={{display: "flex", flexDirection:"column"}}>
                                        <IconButton>
                                            <img src={item.img}/>                                    
                                        </IconButton>
                                        <Typography variant="body1">{item.name}</Typography>
                                    </div>
                                </Grid>
                            )
                        })}                       
                     </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}