import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { configData } from "../../../utils/Config";
import * as THREE from 'three'
import DoorWindows from '../DoorWindows'

export default function DoorManager() {
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);    
    const buildingLength = useSelector((state) => state.buildingReducer.params.length);
    const buildingWidth = useSelector((state) => state.buildingReducer.params.width);
    const buildingHeight = useSelector((state) => state.buildingReducer.params.height);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const baseLegHeight = useSelector((state) => state.buildingReducer.params.baseLegHeight);
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);

    const [overlappedComponents, setOverlappedComponents] = useState(new Set());
    const dispatch = useDispatch();
    const trimWidth = 0.25 * configData.wScale;
    const doorThickness = 5;


    const doorMovementData = useMemo(() => {
        const bottomLimit = (baseHeight + baseLegHeight) * configData.hScale;
        const topLimit = (baseHeight + baseLegHeight + buildingHeight) * configData.hScale;
        const movementData = {
            "front": {
                rotation: [0, 0, 0],
                leftLimit: -(buildingWidth / 2) * configData.wScale,
                rightLimit: (buildingWidth / 2) * configData.wScale,
                topLimit,
                bottomLimit,
                defaultPosition:  new THREE.Vector3(0, bottomLimit, (buildingLength / 2 - porchSize) * configData.wScale - 2),
                coordinate: "x"
            },
            "back": {
                rotation: [0, Math.PI, 0],
                leftLimit: -(buildingWidth / 2) * configData.wScale,
                rightLimit: (buildingWidth / 2) * configData.wScale,
                topLimit,
                bottomLimit,
                defaultPosition:  new THREE.Vector3(0, bottomLimit, -(buildingLength / 2) * configData.wScale + 2),
                coordinate: "x"
            },
            "left": {
                rotation: [0, -Math.PI/2, 0],
                leftLimit: -(buildingLength / 2) * configData.wScale,
                rightLimit: (buildingLength / 2 - porchSize) * configData.wScale,
                topLimit,
                bottomLimit,
                defaultPosition:  new THREE.Vector3(-(buildingWidth / 2) * configData.wScale + 2, bottomLimit, 0),
                coordinate: "z"
            },
            "right": {
                rotation: [0, Math.PI/2, 0],
                leftLimit: -(buildingLength / 2) * configData.wScale,
                rightLimit: (buildingLength / 2 - porchSize) * configData.wScale,
                topLimit,
                bottomLimit,
                defaultPosition:  new THREE.Vector3((buildingWidth / 2) * configData.wScale - 2, bottomLimit, 0),
                coordinate: "z"
            }
        }

        const filteredDoors = doorWindowsData.filter((door) => {
            const wallLimit =  movementData[door.wall];
            if ((wallLimit.rightLimit >= (door.posX + (trimWidth + door.width / 2 * configData.wScale)) && (door.posX - (trimWidth + door.width / 2 * configData.wScale)) >= wallLimit.leftLimit) 
            && ((wallLimit.topLimit - wallLimit.bottomLimit) >= (door.posY + (trimWidth + door.height / 2 * configData.hScale)) && (door.posY - ((door.type === "window" && trimWidth) + door.height / 2 * configData.hScale)) >= 0)) {
                return true;
            }
        })
         
        dispatch({ type: "SET_DOOR_WINDOW_MOVEMENT_DATA", value: movementData});
        dispatch({ type: "SET_DOOR_WINDOW_DATA", value: filteredDoors});


        return movementData;

    }, [buildingLength, buildingWidth, buildingHeight, baseHeight, baseLegHeight, porchSize])


    return doorWindowsData.map((item, index) => {

        return <DoorWindows 
                key={index} 
                indexId={index} 
                thickness={doorThickness}
                trimWidth={trimWidth}
                {...item} 
                {...doorMovementData[item.wall]} 
                overlapped={overlappedComponents.has(item)}
                setOverlappedComponents={setOverlappedComponents} 
            />
        }
    )    
}