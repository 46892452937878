import React from 'react'
import ReactDom from 'react-dom/client'
import App from './App.js'
import './index.css'
import store from './store/index.js'
import { Provider } from 'react-redux'

const container = document.getElementById('root');
const root = ReactDom.createRoot(container);

root.render(
    <Provider store={store}>
        <App/>
    </Provider>
)




