import BackInnerWall from "./BackInnerWall";
import BackOuterWall from "./BackOuterWall";
import BackWallTrim from "./BackWallTrim";

export default function BackWall() {
    return(
        <group>
            <BackInnerWall />
            <BackOuterWall />
            <BackWallTrim />
        </group>
    )
}