import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';


import Floor from './Floor'
import Walls from './Walls'
import Roof from './Roof';
import Porch from './Porch';
import { configData } from '../../../utils/Config';

export default function Building(props) {

    const state = useThree();
    const dispatch = useDispatch();
    const [cameraDirection, setCameraDirection] = useState("front");
    const orbitControlsRef = useSelector((state) => state.envReducer.orbitControlsRef);
    const reduxState = useSelector((state) => state);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const baseLegHeight = useSelector((state) => state.buildingReducer.params.baseLegHeight);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const buildingRef = useRef();
    //console expose
    window.THREE = THREE;
    window.state = state;
    window.initialState = reduxState;

    useFrame(() => {
        const newDirection = ((dir) => {
            if (dir > -Math.PI/4 && dir < Math.PI/4) return "front"
            else if (dir > Math.PI/4 && dir < (3 * Math.PI)/4) return "right"
            else if (Math.abs(dir) > (3 * Math.PI/4)) return "back"
            else if (dir > -(3 * Math.PI)/4 && dir < -Math.PI/4) return "left"
        })(orbitControlsRef.current?.getAzimuthalAngle())
        if (newDirection !== cameraDirection) {
            setCameraDirection(newDirection);
            dispatch({type: "SET_ACTIVE_WALL", value: newDirection});
        }
    })

    useEffect(() => {
        const boxSize = new THREE.Box3().setFromObject(buildingRef.current);
        const diagonalDistance = boxSize.min.distanceTo(boxSize.max);

        //1.
        // const buildingAngle = Math.atan((boxSize.max.x - boxSize.min.x)/(boxSize.max.z - boxSize.min.z));
        // const changedAngle = orbitControlsRef.current.getAzimuthalAngle() + buildingAngle;
        // const changedZ = Math.cos(changedAngle) * diagonalDistance;
        // const changedXDiff = Math.tan(orbitControlsRef.current.getAzimuthalAngle()) * changedZ;

        //2.
        // const changedZ = Math.cos(orbitControlsRef.current.getAzimuthalAngle()) * ((boxSize.max.x - boxSize.min.x)/2);
        // const changedX = Math.sin(orbitControlsRef.current.getAzimuthalAngle()) * ((boxSize.max.z - boxSize.min.z)/2);

        //3.
        const changedZ = Math.cos(Math.atan(diagonalDistance/diagonalDistance)) * ((boxSize.max.x - boxSize.min.x)/2);
        const changedX = Math.sin(Math.atan(diagonalDistance/diagonalDistance)) * ((boxSize.max.z - boxSize.min.z)/2);

        const boxCenter = new THREE.Vector3();
        boxSize.getCenter(boxCenter);
        orbitControlsRef.current.target.set(-changedX/2, boxCenter.y, changedZ/2 );

        // console.log("gggggggggggsasp", gsap);

        window.gsap = gsap;

        const x = () => Math.random() * 500;    //for repeatRefresh need to use function for properties value update
        
        const tween = gsap.to(state.camera.position, {x: diagonalDistance , y: (height / 2) * configData.hScale, z: diagonalDistance,
            // delay: 1,
            // repeatDelay: 0.3,
            duration: 2,
            // yoyo: true,
            // repeat: -1,
            // repeatRefresh: true,
            // repeatDelay: 1,
            // ease: "bounce.in",              // "circ.in", circ.out, circ.inOut
            // yoyoEase: "bounce.in",
            // keyframes: [{x: 0 , y: 50, z: 500, duration: 2, delay: 1}, 
            //     {x: 500, y: 50, z: 0, duration: 1, delay: 1}, 
            //     {x: 0 , y: 50, z: -500, duration: 2, delay: 1}, 
            //     {x: -500, y: 50, z: 0,duration:1, delay: 1}],
            // startAt: {x: Math.random() * 100, y: Math.random() * 50, z: Math.random() * 400},
            // reversed: true,
            // runBackwards: true,
            // stagger: 0.5,    // with multiple targets
            onStart: () => {
                console.log("started");
            },
            // onUpdate: () => {
            //     console.log("updating");
            // },
            // onComplete: () => {
            //     console.log("completed");
            // },
            // onRepeat: () => {
            //     console.log("repeated");
            // },
            // onReverseComplete: () => {
            //     console.log("reverse completed")
            // },
            onInterrupt: () => {
                console.log("interrupted");
            },
            // onStartParams: () => {
            //     console.log("started params")
            // }
        
        });

        console.log("tween", tween);
        window.Tween = tween;
        window.gsap = gsap;

    }, [length, height, width]);

    return (
        <group ref={buildingRef} name='building-group'>
            <group name='building-structure-group' position={[0, (baseLegHeight + baseHeight) * configData.hScale, 0]}>
                <Roof />
                <Walls />
                <Porch />
            </group>
            <Floor rotation={[Math.PI/2, 0, 0]} position={[0, (baseLegHeight + baseHeight) * configData.hScale, 0]}/>
        </group>
    )
}