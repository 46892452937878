import * as THREE from 'three'
import { configData } from '../Config';

export function Interior(type, width, length, porchSize) {
    const shape = new THREE.Shape();
    switch(type) {
        case "loafted_barn_cabin":
            shape.moveTo(-width / 2 * configData.wScale, -length / 2 * configData.wScale);
            shape.lineTo(width / 2 * configData.wScale, -length / 2 * configData.wScale);
            shape.lineTo(width / 2 * configData.wScale, (length / 2 - 9) * configData.wScale);
            shape.lineTo((width / 2 - 5) * configData.wScale, (length / 2 - 9) * configData.wScale);
            shape.lineTo((width / 2 - 5) * configData.wScale, (length / 2 - 6) * configData.wScale);
            shape.lineTo((width / 2 - 8) * configData.wScale, (length / 2 - 3) * configData.wScale);
            shape.lineTo(-(width / 2) * configData.wScale, (length / 2 - 3) * configData.wScale);            
        break;
        case "cabin":
            shape.moveTo(-(width / 2) * configData.wScale, -(length / 2) * configData.wScale);
            shape.lineTo(-(width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
            shape.lineTo((width / 2) * configData.wScale, (length / 2 - porchSize) * configData.wScale);
            shape.lineTo((width / 2) * configData.wScale, -(length / 2) * configData.wScale);
        break;
    }

    return shape;
}