import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import * as THREE from 'three'
import { ExtTrimTexture, extrudeSettings } from "../../../../../utils/Functions";
import { configData } from "../../../../../utils/Config";

export default function MainRoofTrim() {

    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const sideExt = useSelector((state) => state.buildingReducer.params.sideExtension);    
    const endExt = useSelector((state) => state.buildingReducer.params.endExtension);   
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);   
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const fTrim = useRef();
    const bTrim = useRef();
    const lTrim = useRef();
    const rTrim = useRef();    
    const [texture, isNormalMap] = ExtTrimTexture();
    const roofGap = 0.375 * configData.wScale;    //4.5 inch (9/24(ratio of scale value where 2 = 1inch))
    const trimThickness = 0.083 * configData.wScale   //1 inch
    const wallThinknessRoofGap = 6 / configData.wScale;
    const alpha = Math.atan(roofPitch / 12);        //pitch angle
    const extendedWidth = (width / 2 +  (Math.cos(alpha) * sideExt)) * configData.wScale;
    const extendedLength = (length + (2 * endExt)) * configData.wScale + (2 * trimThickness);
    const h = ((roofPitch / 12) * (width / 2)) * configData.hScale;             //height upto innerroof from building height
    const h1 = (wallThinknessRoofGap / Math.cos(alpha)) * configData.hScale;    //height upto middleroof from innerroof
    const h2 = ((roofPitch / 12) * extendedWidth / configData.wScale) * configData.hScale; //height of total extendedwidth roof
    const h3 = (roofGap / Math.cos(alpha)) ;    //trim height



    const endTrim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, h1 + h);
        shape.lineTo(extendedWidth, (h1 + h) - h2);
        shape.lineTo(extendedWidth, (h1 + h) - h2 + h3);
        shape.lineTo(0, (h1 + h) + h3);
        shape.lineTo(-extendedWidth, (h1 + h) - h2 + h3);
        shape.lineTo(-extendedWidth, (h1 + h) - h2);        
        return shape;
    }, [h, h1, h2, h3]);

    const sideTrim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(extendedLength, 0);
        shape.lineTo(extendedLength, h3);
        shape.lineTo(0, h3);
        return shape;
    }, [extendedLength]);


    useEffect(() => {
        lTrim.current.needsUpdate = true;
        rTrim.current.needsUpdate = true;
        fTrim.current.needsUpdate = true;
        bTrim.current.needsUpdate = true;
    }, [isNormalMap, transparent]);

    const rTrimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:trimColor,        
        specular,
    }
    
    isNormalMap && (rTrimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace

    return (
        <group>
            {/* BACK */}
            <mesh castShadow receiveShadow name="roof-back-trim" position={[0, 0, -extendedLength/2]}>
                <extrudeGeometry args={[endTrim, extrudeSettings(trimThickness)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={bTrim} {...rTrimMatProps} />
            </mesh>

            {/* FRONT */}
            <mesh castShadow receiveShadow name="roof-front-trim" position={[0, 0, extendedLength/2 -trimThickness]}>
                <extrudeGeometry args={[endTrim, extrudeSettings(trimThickness)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={fTrim} {...rTrimMatProps} />
            </mesh>

            {/* RIGHT */}
            <mesh castShadow receiveShadow name="roof-right-trim" position={[extendedWidth, (h1 + h - h2 + h3), -(extendedLength / 2)]} rotation={[0, Math.PI/2, Math.PI]}>
                <extrudeGeometry args={[sideTrim, extrudeSettings(trimThickness)]} />
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={rTrim} {...rTrimMatProps} />
            </mesh>

            {/* LEFT */}
            <mesh castShadow receiveShadow name="roof-left-trim" position={[-extendedWidth, (h1 + h - h2 + h3), (extendedLength / 2)]} rotation={[0, -Math.PI/2, Math.PI]}>
                <extrudeGeometry args={[sideTrim, extrudeSettings(trimThickness)]} />
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={lTrim} {...rTrimMatProps} />
            </mesh>                            
        </group>
    )
}