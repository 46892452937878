import { useEffect, useMemo, useRef } from "react"
import { ExtTrimTexture, extrudeSettings } from "../../../../utils/Functions"
import * as THREE from 'three'
import { useSelector } from "react-redux";
import ColonistDoor from "../ColonistDoor";

export default function ColonistDoubleDoor({width, height, trimWidth, thickness}) {
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const [texture, isNormalMap] = ExtTrimTexture();
    const trimRef = useRef();

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    useEffect(() => {
        (trimRef.current.needsUpdate = true);
    }, [isNormalMap]); 


    const trim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo( -width/2, -height/2);
        shape.lineTo( -width/2, height/2);
        shape.lineTo(  width/2, height/2);
        shape.lineTo(  width/2, -height/2);
        shape.lineTo(  width/2 + trimWidth, -height/2);
        shape.lineTo(  width/2 + trimWidth, height/2 + trimWidth);
        shape.lineTo(  -width/2 - trimWidth, height/2 + trimWidth);
        shape.lineTo(  -width/2 - trimWidth, -height/2);
        shape.closePath();
        return shape;

    }, [width, height, trimWidth])

    return (
        <group>
            <mesh name="outer-trim">
                <extrudeGeometry args={[trim, extrudeSettings(thickness, 0, 0, 1)]}/>
                <meshStandardMaterial ref={trimRef} {...trimMatProps}/>
            </mesh>
            
            {                
                Array(2).fill(0).map((item, index) => {
                    return <ColonistDoor
                    key={index}
                    width={width/2} 
                    height={height} 
                    trimWidth={0}
                    thickness={5} 
                    position={[Math.pow(-1, index) * width/4, 0, 0]}
                    />
                })
            }

        </group>
    )
}