import React, { useEffect } from "react";
import Builder from "./Builder";
import { Stack } from "@mui/material";  
import Header from "./Header";
import SidePanel from "./SidePanel";
import { useDispatch } from "react-redux";
import { useLoader, extend } from '@react-three/fiber';
import { getBuildingData } from "../utils/Config";
import DoorWindowEditDialog from "./Dialogs/DoorWindowEditDialog";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import gltf from './Fonts/box.gltf'


extend({TextGeometry, Line2, LineMaterial})

export default function Components() {
    const dispatch = useDispatch();
    const [/* optimerBold, helvetikerRegular, */ droidSans] = useLoader(FontLoader, [
            // '../assets/fonts/optimer_bold.typeface.json',
            // '../assets/fonts/helvetiker_regular.typeface.json',
            '../assets/fonts/droid_sans_bold.typeface.json'
        ]
    );

    useEffect(() => {
        const buildingData = getBuildingData();
        buildingData.fonts = {
            // optimerBold,
            // helvetikerRegular,
            droidSans
        }
        dispatch({
            type: "SET_BUILDING_DATA",
            payload: buildingData
        })
    },[]);

    return(
        <>
            <Header />
            <Stack direction={"row"} height={'93%'}>
                <Builder />
                <SidePanel />
            </Stack>
            <DoorWindowEditDialog />
        </>

    )
}