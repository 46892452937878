import { useSelector } from "react-redux"
import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import Images from '../../../assets/image'
import { TextureLoader, RepeatWrapping } from "three";


export default function Ground(props) {

    const isGround = useSelector((state) => state.envReducer.isGround);
    const isShadowGround = useSelector((state) => state.envReducer.isShadowGround);
    const isFog = useSelector((state) => state.envReducer.isFog);
    const isBackground = useSelector((state) => state.envReducer.isBackground);

    const texture = useMemo(() => {
        const tex = new TextureLoader().load(Images.grass);
        tex.repeat.set(20, 20);
        tex.wrapS = RepeatWrapping;
        tex.wrapT = RepeatWrapping;
        return tex;
    }, [])

    return (
        <>
            {
                isShadowGround && 
                <group name="shadow-ground-group">
                    <mesh receiveShadow rotation={[-Math.PI/2, 0, 0]}>
                        <planeGeometry args={[1000, 1000]}/>
                        <shadowMaterial opacity={0.3}/>
                    </mesh>
                </group>
            }
            {
                isGround &&
                <group name="ground-group">
                    <mesh name="ground" castShadow receiveShadow rotation={[-Math.PI/2, 0, 0]}>
                        <circleGeometry args={[props.rad, 32]} />
                        {isBackground ? <meshPhongMaterial color={"#fff"} map={texture}/>: <meshPhongMaterial color={"white"} /> }
                    </mesh>
                </group> 
            }
            
            {
                isFog && <fog attach={"fog"} args={["#f2f2f2", 2500, 7500]}/>
            }
        </>
    )
}