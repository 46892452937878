import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from 'redux-thunk'
import buildingReducer from "./reducer/buildingReducer";
import envReducer from "./reducer/envReducer";

const reducer = combineReducers({
    buildingReducer,
    envReducer
})

const store = createStore(reducer, compose(applyMiddleware(thunk)));
export default store;

