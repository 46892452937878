import { Accordion, AccordionSummary, AccordionDetails, Stack, MenuItem, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";


export default function SizeComponent({expanded, onChange}) {
    const width = useSelector((state) => state.buildingReducer.params.width);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const minHeight = useSelector((state) => state.buildingReducer.params.minHeight);
    const maxHeight = useSelector((state) => state.buildingReducer.params.maxHeight);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const minPitch = useSelector((state) => state.buildingReducer.params.minPitch);
    const maxPitch = useSelector((state) => state.buildingReducer.params.maxPitch);
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const minPorchSize = useSelector((state) => state.buildingReducer.params.minPorchSize);
    const maxPorchSize = useSelector((state) => state.buildingReducer.params.maxPorchSize);
    const buildingSizes = useSelector((state) => state.buildingReducer.params.buildingSizes);
    
    const dispatch = useDispatch();

    const onChangeBuildingDimension = (event) => {
        dispatch({
            type: "CHANGE_BUILDING_DIMENSION",
            payload: event.target.value
        })
    }

    const onChangeBuildingHeight = (event) => {
        dispatch({
            type: "CHANGE_BUILDING_HEIGHT",
            payload: event.target.value
        })
    }

    const onChangeBuildingPitch = (event) => {
        dispatch({
            type: "CHANGE_BUILDING_PITCH",
            payload: event.target.value
        })
    }

    const onChangePorchSize = (event) => {
        dispatch({
            type: "CHANGE_PORCH_SIZE",
            payload: event.target.value
        })
    }

    return (
        <>
            <Accordion expanded={expanded === "size"} onChange={(event, isExpanded) => onChange(isExpanded, "size")} elevation={0} disableGutters /* square */ >
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>
                    <Typography>SIZE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h6">Select Size</Typography>
                    <Stack sx={{ padding: '20px'}} justifyContent={'center'} /* alignItems={'space-between'} */>
                        <Stack  sx={{padding: '20px'}}  justifyContent={'space-around'} direction={"row"} >
                            <TextField 
                                select
                                variant="standard"
                                helperText="Width x Length"
                                value={`${width}x${length}`}
                                onChange={(event) => onChangeBuildingDimension(event)}
                                sx={{width: '100px'}}
                                // size="small"
                            >
                                {
                                    // Array(Math.floor((length)/dimensionGap) - startFrom).fill(0).map((item, index) => {
                                    //     return <MenuItem value={(index + startFrom) * dimensionGap}>{(index + startFrom) * dimensionGap}</MenuItem>
                                    // })

                                    buildingSizes.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                            <TextField 
                                select
                                variant="standard"
                                helperText="Height"
                                value={`${height}`}
                                onChange={(event) => onChangeBuildingHeight(event)}
                                sx={{width: '80px'}}
                            >
                                {
                                    Array(maxHeight - minHeight).fill(0).map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={index + minHeight}>{index + minHeight}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Stack>
                        <Stack sx={{padding: '20px'}}  justifyContent={'space-around'} direction={"row"} /* alignItems={'center'} */>
                            <TextField 
                                select
                                variant="standard"
                                helperText="Pitch"
                                value={`${roofPitch}`}
                                onChange={(event) => onChangeBuildingPitch(event)}
                                sx={{width: '80px'}}
                            >
                                {
                                    Array(maxPitch - minPitch).fill(0).map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={index + minPitch}>{index + minPitch}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                            <TextField 
                                select
                                variant="standard"
                                helperText="Porch Size"
                                value={`${porchSize}`}
                                onChange={(event) => onChangePorchSize(event)}
                                sx={{width: '80px'}}
                            >
                                {
                                    Array(maxPorchSize - minPorchSize).fill(0).map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={index + minPorchSize}>{index + minPorchSize}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    )
}