import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'


export default function DoorWindowEditDialog() {
    const openDialog = useSelector((state) => state.buildingReducer.params.openDoorWindowEditDialog);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);
    const selectedDoorIndex = useSelector((state) => state.buildingReducer.params.selectedDoorIndex);
    const doorWindowsUIData = useSelector((state) => state.buildingReducer.params.doorWindowsUIData);
    const dispath = useDispatch();

    let selection1 = null;

    const handleDoorWindowUpdate = (e) => {
        dispath({
            type: "UPDATE_DOOR_WINDOW",
            value: e.target.value
            
        });
    }   

    if (selectedDoorIndex != -1) {
        const selectedItem = doorWindowsData[selectedDoorIndex];
        const items = doorWindowsUIData.filter((item) => item.category === selectedItem.category);
        selection1 = (<>
                        <FormLabel>{selectedItem.category}</FormLabel>
                        <RadioGroup onChange={handleDoorWindowUpdate} value={selectedItem.value}>
                            {
                                items.map((item, index) => {
                                    return (
                                            <FormControlLabel key={index} control={<Radio size="small"/>} label={item.name} value={item.value} />
                                        )
                                    })
                                }
                        </RadioGroup>
                    </>)
    }




    const handleClick = (type, index) => {
        dispath({
            type,
            value: index
        })
    }

    return (
        // <div>
        //     Prince
        // </div>
        <Dialog open={openDialog} disableEscapeKeyDown>
            {/* <DialogTitle>
                Prince 
            </DialogTitle> */}
            <DialogContent>
                <FormControl>
                    {
                        selection1
                    }
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => handleClick("CLONE_DOOR_WINDOW", selectedDoorIndex)}>Clone</Button>
                <Button onClick={(e) => handleClick("REMOVE_DOOR_WINDOW", selectedDoorIndex)}>Remove</Button>
                <Button onClick={(e) => handleClick("CLOSE_DOOR_WINDOW", selectedDoorIndex)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}