import React, { useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Canvas, extend } from "@react-three/fiber"
import { OrbitControls } from '@react-three/drei'
// import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
// import { Line2 } from 'three/examples/jsm/lines/Line2'
import Building from "./Building/index"
import Ground from "./Ground"
import Light from "./Light"
import Background from "./Background"
import DoorManager from "./DoorManager"

// extend({Line2, LineMaterial})

export default function Builder() {
    const dispatch = useDispatch();
    const orbitControlsRef = useRef();

    

    useEffect(() => {
        dispatch({
            type: "addOrbitControlsRef",
            payload: orbitControlsRef
        })
    }, [])


    return (    
        <Canvas
            camera={{
                fov: "45",
                aspect: window.innerWidth/window.innerHeight,
                near: 5,
                far: 100000,
                position: [200, 200, 800]
            }}
            // style={{backgroundColor: "#fffff" /* "#f2f2f2" */}}
            shadows
            // gl={{}}
            flat={true}
            
        >
            <OrbitControls  
                ref={orbitControlsRef}
                maxPolarAngle={Math.PI/2}
                minDistance={0}
                maxDistance={3000}
                rotateSpeed={0.3}
                dampingFactor={0.1}
                target={[0, 100, 0]}
            />
            <Building />
            <DoorManager />
            <Background rad={50000} />
            <Ground rad={10000} />
            <Light name="lights2"/>
        </Canvas>
    )
}
