import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../utils/Config";
import { ExtRoofTexture, extrudeSettings } from "../../../../../utils/Functions";

export default function MainTopRoof() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);    
    const sideExt = useSelector((state) => state.buildingReducer.params.sideExtension);    
    const endExt = useSelector((state) => state.buildingReducer.params.endExtension);    
    const roofColor = useSelector((state) => state.buildingReducer.params.exteriorRoofColor);   
    const specular = useSelector((state) => state.buildingReducer.params.specular); 
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);

    // const thickness = 0.1;                                                  // 2 /  configData.wScale(20)
    const wallThinknessRoofGap = 6/configData.wScale   //0.25 + 0.375;  
    const roofGap = 0.375 * configData.wScale;    //4.5 inch (9/24(ratio of scale value where 2 = 1inch))                  //gap from height h
    const alpha = Math.atan(roofPitch / 12);        
    const extendedWidth = (width / 2 +  (Math.cos(alpha) * sideExt))       //extended width
    const extendedLength = length + 2 * endExt;
    const h = ((roofPitch / 12) * (width / 2)) * configData.hScale;
    const h1 = (wallThinknessRoofGap / Math.cos(alpha)) * configData.hScale;                          //h1 - lower roof to middle roof slant height
    const h2 = (roofPitch / 12) * extendedWidth;    
    const h3 = (roofGap / Math.cos(alpha)) ;    //trim height                        //height of extended roof
    const slantheight = Math.sqrt(((extendedWidth * configData.wScale) **2 + (h2 * configData.hScale) **2));
    const alphaScaled = Math.atan((h2 * configData.hScale) / (extendedWidth * configData.wScale));
    const lRef = useRef();
    const rRef = useRef();
    // const h3 = thickness / Math.cos(alpha);                                 //height due to roofthickness

    // const middleRoof = useMemo(() => {
    //     const thickness = 0.1;
    //     const wallThinknessRoofGap = 10 / configData.wScale;
    //     const alpha = Math.atan(roofPitch / 12);
    //     const extendedWidth = (width / 2 +  (Math.cos(alpha) * sideExt))
    //     const h = (roofPitch / 12) * (width / 2);
    //     const h1 = wallThinknessRoofGap / Math.cos(alpha);
    //     const h2 = (roofPitch / 12) * extendedWidth;
    //     const h3 = thickness / Math.cos(alpha);

    //     const shape = new THREE.Shape();
    //     shape.moveTo(0, (h1 + h) * configData.hScale);
    //     shape.lineTo( extendedWidth * configData.wScale, ((h1 + h) - h2) * configData.hScale);
    //     shape.lineTo(extendedWidth * configData.wScale, ((h1 + h) - h2 + h3) * configData.hScale);
    //     shape.lineTo(0,((h1 + h) + h3) * configData.hScale);
    //     shape.lineTo(-extendedWidth * configData.wScale, ((h1 + h) - h2 + h3) * configData.hScale);
    //     shape.lineTo(-extendedWidth * configData.wScale, ((h1 + h) - h2) * configData.hScale);
    //     shape.closePath();

    //     return shape;
    // }, [width, roofPitch])

    const topLeftRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, extendedLength * configData.wScale);
        shape.lineTo(-slantheight, extendedLength * configData.wScale);
        shape.lineTo(-slantheight, 0);
        shape.closePath();
        return shape;
    }, [extendedWidth, extendedLength, roofPitch]);

    const topRightRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, extendedLength * configData.wScale);
        shape.lineTo(slantheight, extendedLength * configData.wScale);
        shape.lineTo(slantheight, 0);
        shape.closePath();
        return shape;
    }, [extendedWidth, extendedLength, roofPitch]);

    const texture = ExtRoofTexture();

    const roofMatProps = {  
        map: texture,
        "map-colorSpace": THREE.LinearSRGBColorSpace,
        bumpMap: texture,
        // "bumpMap-colorSpace": THREE.LinearSRGBColorSpace,
        bumpScale: 1,
        color: roofColor,        
        specular,
    }

    useEffect(() => {
        lRef.current.needsUpdate = true;
        rRef.current.needsUpdate = true;
    }, [transparent]);


    return (
        // <mesh position={[0, 0, -(length / 2 + endExt) * configData.wScale]}>
        //     <extrudeGeometry args={[middleRoof, extrudeSettings((length + (endExt * 2)) * configData.wScale)]}/>
        //     <meshPhongMaterial map={texture} color={iRoofColor} side={THREE.DoubleSide}/>
        // </mesh>
        <group name="top-roof">
            <mesh name="top-left-roof" castShadow receiveShadow position={[ 0, h + h1 + h3, -(extendedLength / 2) * configData.wScale]} rotation={[Math.PI/2, alphaScaled, 0]}>
                <extrudeGeometry args={[topLeftRoof, extrudeSettings(0.1)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={lRef} {...roofMatProps} />
            </mesh>

            <mesh name="top-right-roof" castShadow receiveShadow position={[ 0, h + h1 + h3, -(extendedLength / 2) * configData.wScale]} rotation={[Math.PI/2, -alphaScaled, 0]}>
                <extrudeGeometry args={[topRightRoof, extrudeSettings(0.1)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={rRef} {...roofMatProps} />
            </mesh>
        </group>
    )
}