import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import * as THREE from 'three'
import { configData } from '../../../../../../../utils/Config';
import { extrudeSettings } from '../../../../../../../utils/Functions';
import { IntSidingTexture } from '../../../../../../../utils/Functions';


export default function InnerWall() {

    const length = useSelector((state) => state.buildingReducer.params.length)
    const width = useSelector((state) => state.buildingReducer.params.width)
    const height = useSelector((state) => state.buildingReducer.params.height)
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);

    const scaledHalfHeight = (height / 2) * configData.hScale;;

    const innerWall = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(-(length / 2 - porchSize) * configData.wScale + 1, -scaledHalfHeight);
        shape.lineTo(-(length / 2 - porchSize) * configData.wScale + 1, scaledHalfHeight);
        shape.lineTo((length / 2) * configData.wScale, scaledHalfHeight);
        shape.lineTo((length / 2) * configData.wScale, -scaledHalfHeight);
        shape.closePath();

        const doors = doorWindowsData.filter((door) => door.wall === "left");
        doors.forEach((door) => {
            const path = new THREE.Path();
            path.moveTo(-(door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.lineTo(-(door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.closePath();
            shape.holes.push(path);
        })
        return shape;
    }, [length, height, porchSize, doorWindowsData]);

     const innerWallTexture = IntSidingTexture();

    return (
        <mesh name='left-inner-wall' castShadow receiveShadow position={[-(width / 2) * configData.wScale + 1, scaledHalfHeight, 0]} rotation={[0, Math.PI / 2, 0]}>
            <shapeGeometry args={[innerWall]}/>
            <meshPhongMaterial map={innerWallTexture}/>
        </mesh>
    )
}