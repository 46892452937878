import { Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


export default function InteriorMaterialComponent({expanded, onChange}) {
    const interiorRoofImages = useSelector((state) => state.buildingReducer.params.interiorRoofImages);
    const interiorSidingImages = useSelector((state) => state.buildingReducer.params.interiorSidingImages);
    const interiorFloorImages = useSelector((state) => state.buildingReducer.params.interiorFloorImages);
    const dispatch = useDispatch();

    const handleInteriorTextureChange = (type, payload) => {
        dispatch({
            type,
            payload
        })
    }

    return (
        <>
            <Accordion expanded={expanded === "interiorMaterial"} onChange={(event, isExpanded) => onChange(isExpanded, "interiorMaterial")} elevation={0} disableGutters>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>   
                    <Typography>INTERIOR MATERIAL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={5}>
                        <Stack>
                            <Typography>Interior Roof Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {interiorRoofImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleInteriorTextureChange("SELECT_INTERIOR_ROOF_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Stack>
                            <Typography>Interior Siding Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {interiorSidingImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleInteriorTextureChange("SELECT_INTERIOR_SIDE_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Stack>
                            <Typography>Interior Floor Material</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {interiorFloorImages.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => {handleInteriorTextureChange("SELECT_INTERIOR_FLOOR_TEXTURE", item)}}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: 'red'}}>
                                                <img style={{width: '100%', height: '100%'}} src={item.img} />
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    )
}