import { useSelector } from "react-redux";
import { ExtTrimTexture, extrudeSettings } from "../../../../../utils/Functions";
import * as THREE from 'three'

export default function PorchWall(props) {

    const specular = useSelector((state) => state.buildingReducer.params.specular);

    const [texture, isNormalMap] = ExtTrimTexture();

    const width = props.width;
    const height = props.height;
    const thickness = 2;
    const depth = 4;
    const bottomHeight = 4;
    const count = parseInt(props.width / 7);

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: 'black',        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace

    const createBoxGeometry = (x, y) => {
        const shape = new THREE.Shape();
        shape.moveTo(-x/2, -y/2);
        shape.lineTo(-x/2, y/2);
        shape.lineTo(x/2, y/2);
        shape.lineTo(x/2, -y/2);
        shape.closePath();
        return shape;
    }

    return (
        <group {...props} >
            <mesh castShadow receiveShadow name="left" position={[thickness/2, (height )/2, -depth/2]}>
                <extrudeGeometry args={[createBoxGeometry(thickness, height), extrudeSettings(depth)]} />
                <meshPhongMaterial {...trimMatProps} />
            </mesh>
            <mesh castShadow receiveShadow name="right" position={[width - thickness/2, (height )/2, -depth/2]}>
                <extrudeGeometry args={[createBoxGeometry(thickness, height), extrudeSettings(depth)]} />
                <meshPhongMaterial {...trimMatProps} />
            </mesh>
            <mesh castShadow receiveShadow name="top" position={[width/2, (height - thickness/2), -depth/2]}>
                <extrudeGeometry args={[createBoxGeometry(width, thickness), extrudeSettings(depth)]} />
                <meshPhongMaterial {...trimMatProps} />
            </mesh>
            <mesh castShadow receiveShadow name="front-top" position={[width/2, (height - thickness), thickness/2]} rotation={[Math.PI/2, 0, 0]}>
                <extrudeGeometry args={[createBoxGeometry(width, thickness), extrudeSettings(depth)]} />
                <meshPhongMaterial {...trimMatProps} />
            </mesh>
            <mesh castShadow receiveShadow name="front-bottom" position={[width/2, bottomHeight + depth, thickness/2]} rotation={[Math.PI/2, 0, 0]}>
                <extrudeGeometry args={[createBoxGeometry(width, thickness), extrudeSettings(depth)]} />
                {/* <boxGeometry args={[width, thickness, depth]} /> */}
                <meshPhongMaterial {...trimMatProps} />
            </mesh>

            {
                Array(count).fill(0).map((item, index) =>(
                        <mesh castShadow receiveShadow key={index} position={[index * 7, (height + depth)/2, -1.5/2]}>
                            <boxGeometry args={[1.5, height - bottomHeight - depth, 1.5]}/>
                            <meshPhongMaterial />
                        </mesh>
                    )
                )
            }

        </group>
    )
}