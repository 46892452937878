import { useSelector } from "react-redux"
import { useMemo } from "react"
import * as THREE from 'three'
import { configData } from "../../../../../../../utils/Config";
import { extrudeSettings, IntSidingTexture } from "../../../../../../../utils/Functions";

export default function FrontInnerWall() {
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const length = useSelector((state) => state.buildingReducer.params.length);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const porchSize = useSelector((state) => state.buildingReducer.params.porchSize);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);

    const scaledHalfHeight = (height / 2) * configData.hScale;;

    const frontInnerWall = useMemo(() => {
        const alpha = Math.atan(roofPitch / 12);
        const wallThinknessRoofGap = 6 / configData.wScale;
        const h4 = (roofPitch / 12) * (4 / configData.wScale);
        const h1 = wallThinknessRoofGap / Math.cos(alpha);
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale, -(height /2) * configData.hScale);
        shape.lineTo(-(width / 2) * configData.wScale, (height /2 + (h1 - h4)) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, (height /2 + (h1 - h4)) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, -(height /2) * configData.hScale);
        shape.closePath();

        const doors = doorWindowsData.filter((door) => door.wall === "front");
        doors.forEach((door) => {
            const path = new THREE.Path();
            path.moveTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.lineTo(-(door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale + door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.closePath();
            shape.holes.push(path);
        })

        return shape;
    }, [width, height, length, porchSize, doorWindowsData]);

    const innerWallTexture = IntSidingTexture();

    return (
        <mesh name="front-inner-wall" castShadow receiveShadow position={[0, scaledHalfHeight, (length / 2 - porchSize) * configData.wScale -1]}>
            <shapeGeometry args={[frontInnerWall]}/>
            <meshPhongMaterial map={innerWallTexture} side={1}/>
        </mesh>
    )
}