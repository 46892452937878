import { useSelector } from "react-redux"
import { useEffect, useMemo, useRef } from "react"
import * as THREE from 'three'
import { configData } from "../../../../../../../utils/Config";
import { ExtTrimTexture, extrudeSettings } from "../../../../../../../utils/Functions";


export default function BackWallTrim() {

    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const lTrimRef = useRef();
    const rTrimRef = useRef();
    const [texture, isNormalMap] = ExtTrimTexture();
    const alpha = Math.atan(roofPitch / 12);
    const wallThinknessRoofGap = 6 / configData.wScale;
    const h1 = wallThinknessRoofGap / Math.cos(alpha);
    const trimWidth = 0.25 * configData.wScale;
    const trimThickness = 0.083 * configData.wScale; //1inch
    const offsetHeight = (roofPitch/12 * trimWidth)

    useEffect(() => {
        lTrimRef.current.needsUpdate = true;
        rTrimRef.current.needsUpdate = true;
    }, [isNormalMap, transparent]); 

    const trim = useMemo(() => {
        const shapeR = new THREE.Shape();
        shapeR.moveTo(0, 0);
        shapeR.lineTo(trimWidth - trimThickness, 0);
        shapeR.lineTo(trimWidth - trimThickness, trimThickness);
        shapeR.lineTo(- trimThickness, trimThickness);
        shapeR.lineTo(- trimThickness, trimThickness - trimWidth);
        shapeR.lineTo(0, trimThickness - trimWidth);
        return shapeR;
    }, [length, width, height]);

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace

    return (
        <group>
            <mesh name="back-left-trim" castShadow receiveShadow position={[-width/2 * configData.wScale - trimThickness/2, -baseHeight * configData.hScale, -(length / 2) * configData.wScale - trimThickness/2]} rotation={[-Math.PI/2, 0, 0]}>
                <extrudeGeometry args={[trim, extrudeSettings((height + h1 + baseHeight) * configData.hScale + offsetHeight)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={lTrimRef} {...trimMatProps} />
            </mesh>
            <mesh name="back-right-trim" castShadow receiveShadow position={[width/2 * configData.wScale + trimThickness/2, -baseHeight * configData.hScale, -(length / 2) * configData.wScale - trimThickness/2]} rotation={[-Math.PI/2, 0, -Math.PI/2]}>
                <extrudeGeometry args={[trim, extrudeSettings((height + h1 + baseHeight) * configData.hScale + offsetHeight)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={rTrimRef} {...trimMatProps} />
            </mesh>
        </group>
    )
}