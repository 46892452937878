import { AppBar, Toolbar, IconButton, Typography, Stack, Button } from "@mui/material"
import { CatchingPokemon, Height } from "@mui/icons-material"

export default function Header() {

    return (
        <AppBar position="static" sx={{height: '7%'}} >
            <Toolbar>
                <IconButton color="inherit">
                    <CatchingPokemon />
                </IconButton>
                <Typography variant="h6" component={'div'} sx={{flexGrow: 1}}>
                        CONFIGURATOR
                </Typography>
                <Stack direction={"row"} spacing={2} >
                    <Button color="inherit">Home</Button>
                    <Button color="inherit">Features</Button>
                    <Button color="inherit">About</Button>
                    <Button color="inherit">Login</Button>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}