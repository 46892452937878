import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../utils/Config";
import { IntSidingTexture, ExtSidingTexture, extrudeSettings } from "../../../../../utils/Functions";
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'

// extend({Line2, LineMaterial})

export default function FrontRoof() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const sidingColor = useSelector((state) => state.buildingReducer.params.exteriorSidingColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const sidingTextureName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const sidePanelTabExpanded = useSelector((state) => state.buildingReducer.params.sidePanelTabExpanded);
    const activeWall = useSelector((state) => state.buildingReducer.params.activeWall);
    const [hover, setHover] = useState(false);
    const dispath = useDispatch();
    const meshRef = useRef();
    
    const shouldHighlight = sidePanelTabExpanded === "doorWindows" && hover ? true: false;
    const shouldFocus = sidePanelTabExpanded === "doorWindows" && activeWall === "frontRoof" ? true: false
    
    const innerTexture = IntSidingTexture();
    const sidingTextureObj = ExtSidingTexture();
    const isNormalMap = sidingTextureName.includes('Normal');

    const wallMatProps = {  
        map:isNormalMap? null: sidingTextureObj,
        bumpMap:isNormalMap? null: sidingTextureObj,
        normalMap:isNormalMap? sidingTextureObj: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:sidingColor,        
        specular,
    }
    
    isNormalMap && (wallMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace

    
    const frontRoof = useMemo(() => {
        const alpha = Math.atan(roofPitch / 12);
        const wallThinkness = 1;
        const wallThinknessRoofGap = 6 / configData.wScale;
        const h = (roofPitch / 12) * (width / 2);
        const h1 = wallThinknessRoofGap / Math.cos(alpha);
        const h4 = (roofPitch / 12) * (wallThinkness / configData.wScale);

        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale - wallThinkness, 0);
        shape.lineTo(-(width / 2) * configData.wScale - wallThinkness, (h1 - h4) * configData.hScale);
        shape.lineTo(0, (h1 + h) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale + wallThinkness, (h1 - h4) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale + wallThinkness, 0);
        shape.closePath();
        return shape;
    }, [length, width, roofPitch]);

    const frontInnerRoof = useMemo(() => {
        const h = (roofPitch / 12) * (width / 2);
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale, 2);
        shape.lineTo(0, (h) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, 2);
        shape.closePath();
        return shape;
    }, [width, roofPitch]);

    useEffect(() => {
        meshRef.current.needsUpdate = true;
    }, [isNormalMap, transparent]); 

    const outline = useMemo(() => {
        return new LineGeometry().setPositions(frontRoof.getPoints().map((point) => [...point, 0]).flat(1));
    }, [width, roofPitch]);

    const events = useMemo(() => ({
        onPointerDown: (e) => {
            e.stopPropagation();
            e.face?.normal?.z > 0 && dispath({
                type: "SET_ACTIVE_WALL",
                value: "frontRoof"
            })
        },
        onPointerOver: (e) => {
            e.stopPropagation();
            e.face?.normal?.z >= 0 && setHover(true);
        },
        onPointerLeave: (e) => {
            e.stopPropagation();
            setHover(false);
        }
    }))


    return (
        <group position={[0, 0, (length / 2) * configData.wScale]}>
            <mesh {...events} castShadow receiveShadow name="front-roof">
                <extrudeGeometry args={[frontRoof, extrudeSettings(1)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={meshRef} {...wallMatProps} />
            </mesh>
            {
                shouldFocus 
                && (<>
                    <line2 geometry={outline} >
                        <lineMaterial args={[{color: 'orange', transparent: true, depthTest: false, linewidth: 5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                    <line2 geometry={outline} >
                        <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 2.5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                </>)
            }
            {
                shouldHighlight && <line2 geometry={outline} >
                    <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 1, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                </line2>
            }  
            <mesh castShadow receiveShadow name="front-inner-roof" position={[0, 0, -1]}>
                <shapeGeometry args={[frontInnerRoof]}/>
                <meshPhongMaterial side={1} map={innerTexture} />
            </mesh>
        </group>
    )
}