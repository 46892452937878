import React from "react";
import { useState } from "react";
import StyleComponent from "./Style";
import SizeComponent from "./Size";
import ExteriorMaterialComponent from "./ExteriorMaterial";
import InteriorMaterialComponent from "./InteriorMaterial";
import DoorWindowComponent from "./Door&Windows";
import SummaryComponent from "./Summary";
import ColorComponent from "./Color";
import { useDispatch, useSelector } from "react-redux";


export default function SidePanel() {
    const dispath = useDispatch();
    const expanded = useSelector((state) => state.buildingReducer.params.sidePanelTabExpanded);

    function handleChange(isExpanded, panel) {
        dispath({type: "CHANGE_SIDE_PANEL_TAB_EXPANSION", value: expanded == panel? isExpanded: panel})
    }

    return (
        <div style={{"width": "500px", background: "white", overflow: "auto"}}>
            <StyleComponent expanded={expanded} onChange={handleChange} />
            <SizeComponent expanded={expanded} onChange={handleChange} />
            <ExteriorMaterialComponent expanded={expanded} onChange={handleChange} />
            <ColorComponent expanded={expanded} onChange={handleChange} />
            <DoorWindowComponent expanded={expanded} onChange={handleChange} />
            <InteriorMaterialComponent expanded={expanded} onChange={handleChange} />
            <SummaryComponent expanded={expanded} onChange={handleChange} />
        </div>
    )
}