import { useSelector } from "react-redux";
import { useMemo } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../utils/Config";
import { IntRoofTexture, extrudeSettings } from "../../../../../utils/Functions";

export default function MainInnerRoof() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);      
    const iRoofColor = useSelector((state) => state.buildingReducer.params.outerWallColor);    
     
    const h = (roofPitch / 12) * (width / 2) * configData.hScale;
    const h2 = (roofPitch / 12) * (width/2);
    const slantheight =  Math.sqrt((((width/2) * configData.wScale) **2 + (h2 * configData.hScale) **2));
    const alphaScaled = Math.atan((h2 * configData.hScale) / ((width/2) * configData.wScale));

    const middleLeftRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, length * configData.wScale);
        shape.lineTo(-slantheight, length * configData.wScale);
        shape.lineTo(-slantheight, 0);
        shape.closePath();
        return shape;
    }, [width, length, roofPitch]);

    const middleRightRoof = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, 0);
        shape.lineTo(0, length * configData.wScale);
        shape.lineTo(slantheight, length * configData.wScale);
        shape.lineTo(slantheight, 0);
        shape.closePath();
        return shape;
    }, [width, length, roofPitch]);

    const texture = IntRoofTexture();

    return (
        <group name="inner-roof">
            <mesh castShadow receiveShadow name="inner-left-roof" position={[ 0, h, -(length / 2) * configData.wScale]} rotation={[Math.PI/2, alphaScaled, 0]}>
                <shapeGeometry args={[middleLeftRoof, extrudeSettings(0.01)]}/>
                <meshPhongMaterial map={texture} />
            </mesh>

            <mesh castShadow receiveShadow name="inner-right-roof" position={[ 0, h, -(length / 2) * configData.wScale]} rotation={[Math.PI/2, -alphaScaled, 0]}>
                <shapeGeometry args={[middleRightRoof, extrudeSettings(0.01)]}/>
                <meshPhongMaterial map={texture} />
            </mesh>
        </group>
    )
}