import { useEffect, useMemo, useRef } from "react"
import * as THREE from 'three'
import { ExtTrimTexture, extrudeSettings } from "../../../../utils/Functions";
import { configData } from "../../../../utils/Config";
import { useSelector } from "react-redux";


export default function NineLiteWindow({width, height, trimWidth, thickness}) {
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const [texture, isNormalMap] = ExtTrimTexture();
    const trimRef = useRef();

    const windowThickness = trimWidth;
    const unitY = (height - 2 * windowThickness)/3;
    const unitX = (width - 2 * windowThickness)/3;
    const innerTrimWidth = 0.01 * configData.wScale;

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    useEffect(() => {
        trimRef.current.needsUpdate = true;
    }, [isNormalMap]); 

    const windowTrim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2 + trimWidth, -height / 2 - trimWidth);
        shape.lineTo(  width / 2 + trimWidth, height / 2 + trimWidth);
        shape.lineTo( -width / 2 - trimWidth, height / 2 + trimWidth);
        shape.lineTo( -width / 2 - trimWidth, -height / 2 - trimWidth);
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  width / 2, -height / 2 );
        path.lineTo(  width / 2, height / 2 );
        path.lineTo( -width / 2, height / 2 );
        path.lineTo( -width / 2, -height / 2 );
        path.closePath();

        shape.holes.push(path);
        return shape;
    }, [width, height]);

    const window = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2, -height / 2 );
        shape.lineTo(  width / 2, height / 2 );
        shape.lineTo( -width / 2, height / 2 );
        shape.lineTo( -width / 2, -height / 2 );
        shape.closePath();

        const path = new THREE.Path();
        path.moveTo(  width / 2 - windowThickness, -height / 2 + windowThickness);
        path.lineTo(  width / 2 - windowThickness, height / 2 - windowThickness);
        path.lineTo( -width / 2 + windowThickness, height / 2 - windowThickness);
        path.lineTo( -width / 2 + windowThickness, -height / 2 + windowThickness);
        path.closePath();

        shape.holes.push(path);
        return shape;
    }, [width, height]);

    const windowGlass = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(  width / 2, -height / 2 );
        shape.lineTo(  width / 2, height / 2 );
        shape.lineTo( -width / 2, height / 2 );
        shape.lineTo( -width / 2, -height / 2 );
        shape.closePath();
        return shape
    });

    const windowInnerTrim = useMemo(() => {
        const shapes = [];

        const shape1 = new THREE.Shape();
        shape1.moveTo(-(width) / 2, (unitY + innerTrimWidth) / 2);
        shape1.lineTo((width) / 2, (unitY + innerTrimWidth) / 2);
        shape1.lineTo((width) / 2,  (unitY -innerTrimWidth) / 2);
        shape1.lineTo(-(width) / 2,  (unitY -innerTrimWidth) / 2);
        shape1.closePath();
        shapes.push(shape1);

        const shape2 = new THREE.Shape();
        shape2.moveTo(-(width) / 2, -(unitY + innerTrimWidth) / 2);
        shape2.lineTo((width) / 2, -(unitY + innerTrimWidth) / 2);
        shape2.lineTo((width) / 2,  -(unitY -innerTrimWidth) / 2);
        shape2.lineTo(-(width) / 2,  -(unitY -innerTrimWidth) / 2);
        shape2.closePath();
        shapes.push(shape2);

        const shape3 = new THREE.Shape();
        shape3.moveTo( -(unitX + innerTrimWidth) / 2, -height / 2 );
        shape3.lineTo( -(unitX + innerTrimWidth) / 2,  height / 2 );
        shape3.lineTo( -(unitX - innerTrimWidth) / 2,  height / 2 );
        shape3.lineTo( -(unitX - innerTrimWidth) / 2, -height / 2 );
        shape3.closePath();
        shapes.push(shape3);

        const shape4 = new THREE.Shape();
        shape4.moveTo( (unitX - innerTrimWidth) / 2, -height / 2);
        shape4.lineTo( (unitX - innerTrimWidth) / 2,  height / 2 );
        shape4.lineTo( (unitX + innerTrimWidth) / 2,  height / 2);
        shape4.lineTo( (unitX + innerTrimWidth) / 2, -height / 2);
        shape4.closePath();
        shapes.push(shape4);

        return shapes;
    }, [width, height]);

    return (
        <group>
            <mesh name="outer-trim" >
                <extrudeGeometry args={[windowTrim, extrudeSettings(thickness, 0, 0, 0, 2)]} />
                <meshStandardMaterial ref={trimRef} {...trimMatProps} />
            </mesh>
            <mesh>
                <extrudeGeometry args={[window, extrudeSettings(thickness, 0, 0, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh position={[0, 0, 0.5]}>
                <extrudeGeometry args={[windowInnerTrim, extrudeSettings(thickness - 1, 0.4, 0.4, 0, 2)]} />
                <meshStandardMaterial />
            </mesh>
            <mesh position={[0, 0, 1]}>
                <extrudeGeometry args={[windowGlass, extrudeSettings(thickness - 2)]} />
                <meshStandardMaterial color={'gray'} />
            </mesh>
        </group>
    )
}