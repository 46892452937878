
import * as THREE from 'three'
import { configData } from '../../utils/Config';

export const getDoorWindowPosition = (doorWindowsData, doorWindowMovementData, activeWall, newDoor) => {
    const trimWidth = 0.25 * configData.wScale;
    const newDoorHalfWidth = (newDoor.width * configData.wScale)/2 + trimWidth;
    const newDoorHalfHeight = (newDoor.height * configData.hScale)/2 + trimWidth;
    const newDoorHalfHeightBottom = newDoor.type === 'window' ? (newDoor.height * configData.hScale)/2 + trimWidth: (newDoor.height * configData.hScale)/2;
    const movementLimit = doorWindowMovementData[activeWall];
    const centerX = (movementLimit.leftLimit + movementLimit.rightLimit) / 2;
    const centerY = (newDoor.type === 'window' ? (movementLimit.bottomLimit + movementLimit.topLimit) / 2 - movementLimit.bottomLimit: (newDoorHalfHeight - trimWidth));
    const originX = newDoor.posX || centerX;
    const originY = newDoor.posY || centerY;
    const distX = (Math.abs(movementLimit.leftLimit) + Math.abs(movementLimit.rightLimit));
    const distY = (Math.abs(movementLimit.topLimit) - Math.abs(movementLimit.bottomLimit));
    const currentDoors = doorWindowsData.filter((e) => e.wall === activeWall);

    const pos = new THREE.Vector2(originX, originY);


    
    const checkAvailability = () => { 
        const yLimit = newDoor.type === 'window'? (distY - newDoorHalfHeight): 1;
        const y2Limit = newDoor.type === 'window'? 2: 1;
        
        //limit for y direction
        for(let y = 0; y < yLimit; y +=2) {

            //for covering both positive and negative y direction
            for(let i = 0; i < y2Limit; i++) {

                pos.y = originY + Math.pow(-1, i) * y;

                pos.y = Math.max(newDoorHalfHeightBottom, Math.min(movementLimit.topLimit - newDoorHalfHeight, pos.y));

                // limit for x direction
                for(let x = 0; x < (distX - newDoorHalfWidth); x +=2) {

                    //for covering both positive and negative x direction
                    for(let j = 0; j < 2; j++) {

                        pos.x = originX + Math.pow(-1, j) * x;      
                        pos.x = Math.max(movementLimit.leftLimit + newDoorHalfWidth, Math.min(movementLimit.rightLimit - newDoorHalfWidth, pos.x));
                        let isAvailable = true;

                        currentDoors.forEach(door => {
                            if (((door.posX + ((door.width * configData.wScale)/2 + trimWidth)) > (pos.x - newDoorHalfWidth) &&
                                (door.posX - ((door.width * configData.wScale)/2 + trimWidth)) < (pos.x + newDoorHalfWidth)) && 
                                ((door.posY + ((door.height * configData.wScale)/2 + trimWidth)) > (pos.y - newDoorHalfHeight) &&
                                (door.posY - ((door.height * configData.hScale)/2 + trimWidth)) < (pos.y + newDoorHalfHeight))           
                                ) {
                                isAvailable = false
                            }   
                        });

                        if (isAvailable) return isAvailable;
                    }
                }
            }
        }
        return false;
    }

    return [checkAvailability(), pos.x, pos.y];


}