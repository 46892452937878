import { Accordion, AccordionSummary, AccordionDetails, Stack, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


export default function ColorComponent({expanded, onChange}) {
    const exteriorRoofColors = useSelector((state) => state.buildingReducer.params.exteriorRoofColors);
    const exteriorSidingColors = useSelector((state) => state.buildingReducer.params.exteriorSidingColors);
    const exteriorTrimColors = useSelector((state) => state.buildingReducer.params.exteriorTrimColors);
    const exteriorLegColors = useSelector((state) => state.buildingReducer.params.exteriorLegColors);
    const exteriorFloorColors = useSelector((state) => state.buildingReducer.params.exteriorFloorColors);

    
    const dispatch = useDispatch();

    const handleColorChange = (type, payload) => {
        dispatch({
            type,
            payload
        })
    }

    return (
        <>
            <Accordion expanded={expanded === "color"} onChange={(event, isExpanded) => onChange(isExpanded, "color")} elevation={0} disableGutters>
                <AccordionSummary sx={{background: "lightgrey", borderRadius: "5px", margin: "5px"}} 
                    expandIcon={<ExpandMoreIcon 
                />}>
                    <Typography>COLORS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Typography>Exterior Roof Color</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorRoofColors.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleColorChange( "SELECT_EXTERIOR_ROOF_COLOR", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: `${item.code}`}}>                                            
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>
                        
                        <Divider/>

                        <Stack spacing={1}>
                            <Typography>Exterior Siding Color</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorSidingColors.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleColorChange( "SELECT_EXTERIOR_SIDE_COLOR", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: `${item.code}`}}>                                            
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider/>
                        <Stack spacing={1}>
                            <Typography>Exterior Trim Color</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorTrimColors.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleColorChange( "SELECT_EXTERIOR_TRIM_COLOR", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: `${item.code}`}}>                                            
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider/>

                        <Stack spacing={1}>
                            <Typography>Exterior Leg Color</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorLegColors.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleColorChange( "SELECT_EXTERIOR_LEG_COLOR", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: `${item.code}`}}>                                            
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>

                        <Divider/>

                        <Stack spacing={1}>
                            <Typography>Exterior Floor Color</Typography>
                            <div style={{display: 'flex', flexFlow: 'row wrap'}}>
                                {exteriorFloorColors.map((item, index) => {
                                    return (
                                        <div key={index} title={item.name} style={{padding: '5px', cursor: 'pointer'}} onClick={() => handleColorChange( "SELECT_EXTERIOR_FLOOR_COLOR", item)}>
                                            <div style={{border: '4px solid white', borderRadius: '2px', width: '50px', height: '50px', boxShadow: '0px 0px 0px 1px rgb(208, 208, 208)', background: `${item.code}`}}>                                            
                                            </div>
                                        </div>
                                    
                                    )
                                })}     
                            </div>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    )
}