import { useEffect, useMemo, useRef } from "react"
import { ExtTrimTexture, extrudeSettings } from "../../../../utils/Functions";
import * as THREE from 'three'
import { configData } from "../../../../utils/Config";
import Window from "./Window";
import HoleModel from "../Common/ColonistHoleModel";
import { useSelector } from "react-redux";

export default function ElevenLiteDoor({width, height, trimWidth, thickness, ...props}) {
    const trimColor = useSelector((state) => state.buildingReducer.params.exteriorTrimColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const [texture, isNormalMap] = ExtTrimTexture();
    const trimRef = useRef();

    useEffect(() => {
        trimWidth === 0 && (trimRef.current.needsUpdate = true);
    }, [isNormalMap]); 

    const trimMatProps = {  
        map:isNormalMap? null: texture,
        bumpMap:isNormalMap? null: texture,
        normalMap:isNormalMap? texture: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color: trimColor,        
        specular,
    }
    
    isNormalMap && (trimMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace);

    const actualDoorWidth = width + 2 * trimWidth;
    const actualDoorHeight = height + trimWidth;
    const doorBevel = 0.4;

    const unitX = width / 5;
    const unitY = height / 10;

    const marginX = unitX;
    const marginY = unitY * 0.8;

    const windowWidth = unitX * 3;
    const windowHeight = height / 2 - marginY;

    const holeHeight = 3.2 * unitY;
    const holeWidth = unitX * 1.1;
    const holeDistX = (unitX / 2) * 0.8;

    const trim = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo( -width / 2, -height / 2);
        shape.lineTo( -width / 2, height / 2);
        shape.lineTo( width / 2, height / 2);
        shape.lineTo( width / 2, -height / 2);
        shape.lineTo( actualDoorWidth / 2, -height / 2);
        shape.lineTo( actualDoorWidth / 2, height / 2 + trimWidth);
        shape.lineTo( -actualDoorWidth / 2, height / 2 + trimWidth);
        shape.lineTo( -actualDoorWidth / 2, -height / 2);
        shape.closePath();
        return shape;
    }, [ width,  height,  trimWidth]);

    const door = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo( -(width - doorBevel)/ 2, -(height - doorBevel) / 2);
        shape.lineTo( -(width - doorBevel)/ 2, (height - doorBevel) / 2);
        shape.lineTo(  (width - doorBevel)/ 2, (height - doorBevel) / 2);
        shape.lineTo(  (width - doorBevel)/ 2, -(height - doorBevel) / 2);
        shape.closePath();

        const windowHole = new THREE.Path();
        windowHole.moveTo(  windowWidth / 2, 0);
        windowHole.lineTo(  windowWidth / 2, windowHeight * (2/3) );
        windowHole.quadraticCurveTo(  windowWidth / 2.5, windowHeight, 0,  windowHeight);
        windowHole.quadraticCurveTo(  -windowWidth / 2.5, windowHeight, -windowWidth / 2, windowHeight * (2/3));
        windowHole.lineTo( -windowWidth / 2, 0);
        windowHole.closePath();

        shape.holes.push(windowHole);

        let bottomHole = new THREE.Path();
        bottomHole.moveTo(Math.pow(-1, 1) * holeDistX,            - height / 2 + marginY);
        bottomHole.lineTo(Math.pow(-1, 1) * holeDistX,            - height / 2 + holeHeight + marginY);
        bottomHole.lineTo(Math.pow(-1, 1) * (holeDistX  + holeWidth), - height / 2 + holeHeight + marginY);
        bottomHole.lineTo(Math.pow(-1, 1) * (holeDistX  + holeWidth), - height / 2 + marginY);
        bottomHole.closePath()
        shape.holes.push(bottomHole);

        let bottomHole1 = new THREE.Path();
        bottomHole1.moveTo(Math.pow(-1, 2) * holeDistX,            - height / 2 + holeHeight + marginY);
        bottomHole1.lineTo(Math.pow(-1, 2) * holeDistX,            - height / 2 + marginY);
        bottomHole1.lineTo(Math.pow(-1, 2) * (holeDistX  + holeWidth), - height / 2 + marginY);
        bottomHole1.lineTo(Math.pow(-1, 2) * (holeDistX  + holeWidth), - height / 2 + holeHeight + marginY);
        bottomHole1.closePath()
        shape.holes.push(bottomHole1);          

        return shape;
    }, [ width,  height,  trimWidth]);


    return (
        <group {...props}>
            {
              trimWidth === 0 &&  <mesh name="outer-trim" >
                    <extrudeGeometry args={[trim, extrudeSettings(thickness)]}/>
                    <meshStandardMaterial  ref={trimRef} {...trimMatProps} />
                </mesh> 
            }   
            <mesh>
                <extrudeGeometry args={[door, extrudeSettings(thickness, doorBevel/2, doorBevel/2, 0, 1)]}/>
                <meshStandardMaterial />
            </mesh>
            <group position={[0, windowHeight/2, 0]}> 
                <Window width={windowWidth} height={windowHeight} thickness={thickness} />
            </group>

            {Array(2).fill(0).map((item, index) => <HoleModel 
                key={index}
                position={[Math.pow(-1, index) * (holeDistX + holeWidth/2), -height/2 + marginY + holeHeight/2, 0]} 
                width={holeWidth} 
                height={holeHeight} 
                thickness={thickness}
            />)}
        </group>
    )
}