import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from 'three'
import { configData } from "../../../../../../../utils/Config";
import { ExtSidingTexture, extrudeSettings } from "../../../../../../../utils/Functions";
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'

export default function BackOuterWall() {
    const length = useSelector((state) => state.buildingReducer.params.length);
    const width = useSelector((state) => state.buildingReducer.params.width);
    const height = useSelector((state) => state.buildingReducer.params.height);
    const roofPitch = useSelector((state) => state.buildingReducer.params.roofPitch);
    const baseHeight = useSelector((state) => state.buildingReducer.params.baseHeight);
    const sidingColor = useSelector((state) => state.buildingReducer.params.exteriorSidingColor);
    const specular = useSelector((state) => state.buildingReducer.params.specular);
    const sidingTextureName = useSelector((state) => state.buildingReducer.params.exteriorSidingTexture);
    const doorWindowsData = useSelector((state) => state.buildingReducer.params.doorWindowsData);
    const transparent = useSelector((state) => state.buildingReducer.params.isWallTransparent);
    const opacity = useSelector((state) => state.buildingReducer.params.wallTransparentOpacity);
    const sidePanelTabExpanded = useSelector((state) => state.buildingReducer.params.sidePanelTabExpanded);
    const activeWall = useSelector((state) => state.buildingReducer.params.activeWall);
    const [hover, setHover] = useState(false);
    const dispath = useDispatch();
    const meshRef = useRef();
    
    const isNormalMap = sidingTextureName.includes('Normal');
    const scaledHalfHeight = (height / 2) * configData.hScale;
    const shouldHighlight = sidePanelTabExpanded === "doorWindows" && hover ? true: false;
    const shouldFocus = sidePanelTabExpanded === "doorWindows" && activeWall === "back" ? true: false;
    const sidingTextureObj = ExtSidingTexture();

    const wallMatProps = {  
        map:isNormalMap? null: sidingTextureObj,
        bumpMap:isNormalMap? null: sidingTextureObj,
        normalMap:isNormalMap? sidingTextureObj: null,
        normalScale:[1, 1],
        bumpScale:0.2,
        color:sidingColor,        
        specular,
    }
    
    isNormalMap && (wallMatProps["normalMap-colorSpace"] = THREE.LinearSRGBColorSpace); // normalMap.encoding={THREE.LinearEncoding}            //deprecated - use colorSpace


    const backOuterWall = useMemo(() => {
        const alpha = Math.atan(roofPitch / 12);
        const wallThinknessRoofGap = 6 / configData.wScale;
        const h1 = wallThinknessRoofGap / Math.cos(alpha);
        const shape = new THREE.Shape();
        shape.moveTo(-(width / 2) * configData.wScale, -(height / 2 + baseHeight) * configData.hScale);
        shape.lineTo(-(width / 2) * configData.wScale, (height / 2 + h1) * configData.hScale);
        shape.lineTo(0, (height / 2 + ((roofPitch / 12) * (width / 2)) + h1) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, (height / 2 + h1) * configData.hScale);
        shape.lineTo((width / 2) * configData.wScale, -(height / 2 + baseHeight) * configData.hScale);
        shape.closePath();

        const doors = doorWindowsData.filter((door) => door.wall === "back");
        doors.forEach((door) => {
            const path = new THREE.Path();
            path.moveTo(-(door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.lineTo(-(door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY + (door.height / 2) * configData.hScale);
            path.lineTo((door.width/2) * configData.wScale - door.posX, -scaledHalfHeight + door.posY - (door.height / 2) * configData.hScale);
            path.closePath();
            shape.holes.push(path);
        })

        return shape;
    }, [length, width, height, roofPitch, doorWindowsData]);

    useEffect(() => {
        meshRef.current.needsUpdate = true;
    }, [isNormalMap, transparent]); 

    const outline = useMemo(() => {
        return new LineGeometry().setPositions(backOuterWall.getPoints().map((point) => [...point, 0]).flat(1));
    }, [width, height, roofPitch]);

    const events = useMemo(() => ({
        onPointerDown: (e) => {
            e.stopPropagation();
            e.face?.normal?.z > 0 && dispath({
                type: "SET_ACTIVE_WALL",
                value: "back"
            })
        },
        onPointerOver: (e) => {
            e.stopPropagation();
            e.face?.normal?.z >= 0 && setHover(true);
        },
        onPointerLeave: (e) => {
            e.stopPropagation();
            setHover(false);
        }
    }))

    return (
        <>
            <mesh {...events} name="back-outer-wall" castShadow receiveShadow position={[0, height / 2 * configData.hScale, -(length / 2) * configData.wScale]} rotation={[0, Math.PI, 0]}>
                <extrudeGeometry args={[backOuterWall, extrudeSettings(1)]}/>
                <meshPhongMaterial transparent={transparent} opacity={opacity} ref={meshRef} {...wallMatProps} />
            </mesh>
            {
                shouldFocus 
                && (<>
                    <line2 geometry={outline} position={[0, height / 2 * configData.hScale, -(length / 2) * configData.wScale]} rotation={[0, Math.PI, 0]}>
                        <lineMaterial args={[{color: 'orange', transparent: true, depthTest: false, linewidth: 5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                    <line2 geometry={outline} position={[0, height / 2 * configData.hScale, -(length / 2) * configData.wScale]} rotation={[0, Math.PI, 0]}>
                        <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 2.5, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                    </line2>
                </>)
            }
            {
                shouldHighlight && <line2 geometry={outline} position={[0, height / 2 * configData.hScale, -(length / 2) * configData.wScale]} rotation={[0, Math.PI, 0]}>
                    <lineMaterial args={[{transparent: true, depthTest: false, linewidth: 1, resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)}]} />
                </line2>
            } 
        </>
    )
}