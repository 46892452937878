import { useSelector } from 'react-redux'
import SideWall from './SideWall'
import EndWall from './EndWall'

export default function Walls() {

    return (
        <group name='walls'>
            <SideWall />
            <EndWall />
        </group>
    )
}