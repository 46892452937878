import LeftWall from "./Leftwall"
import RightWall from "./Rightwall"


export default function SideWall() {

    return (
        <group >
            <LeftWall />
            <RightWall />
        </group>
    )
}