import { useSelector } from "react-redux";


export default function DimensionLabel({text, ...props}) {
    const fonts = useSelector((state) => state.buildingReducer.constants.fonts);

    return (
        <group {...props}>
            <mesh>
                <boxGeometry args={[20, 10, 0.01]}/>
                <meshBasicMaterial color={"#2f719c"} depthTest={false} />
            </mesh>
            <mesh position={[-7, -3, 0]} name="dimensionText">
                <textGeometry args={[text, {	
                    font: fonts["droidSans"],	
                    size: 5,
                    height: 0.01,
                    curveSegments: 10,
                    bevelEnabled: false,
                    bevelThickness: 0.1,
                    bevelSize: 0.1,
                    bevelOffset: 0.1,
                    bevelSegments: 1
                }]}/>
                <meshBasicMaterial depthTest={false}/>
            </mesh>
        </group>
    )
}